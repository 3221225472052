import Hamburger from "hamburger-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/el_5000.png";
import "../../stylesheets/NavDE.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import LI from "../../assets/linkedin.svg"
import WA from "../../assets/whatsapp.svg"
import LIB from "../../assets/linkedin_black.svg"
import WAB from "../../assets/whatsapp_black.svg"

class NavDE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "closed",
      rerender: false,
    };
    this.pNav = React.createRef();
    this.menuClick = this.menuClick.bind(this);
  }

  productMenu(e) {
    if (e.value === "Familienrecht") {
      window.open("https://www.kanzlei-e-lutz.de/de/familienrecht", "_self");
    } else {
      window.open("https://www.kanzlei-e-lutz.de/de/migrationsrecht", "_self");
    }
  }

  /*   menuClick(e) {
      let currentState = this.state.open;
      let html = document.querySelector("html");
      this.forceUpdate(); 
      if (currentState === "open") {
        html.classList.toggle("no-scroll")
        document.body.style.overflow = 'unset';
        this.setState({ open: "closed" });
      } else if (currentState === "closed") {
        html.classList.toggle("no-scroll")
        document.body.style.overflow = 'hidden';
        this.setState({ open: "open" });
      }
      this.setState({ rerender: !this.state.rerender });
      this.forceUpdate(); 
    } */

  menuClick(e) {
    let currentState = this.state.open;
    let content = document.querySelector(".App div.content");
    this.forceUpdate();
    if (currentState === "open") {
      content.classList.toggle("no-scroll")
      this.setState({ open: "closed" });
    } else if (currentState === "closed") {
      content.classList.toggle("no-scroll")
      this.setState({ open: "open" });
    }
    this.setState({ rerender: !this.state.rerender });
    this.forceUpdate();
    document.body.style.overflow = 'hidden';
    document.body.style.overflow = 'unset';
  }

  render() {
    const options = [
      'Familienrecht', 'Migrationsrecht'
    ];
    return (
      <nav>
        <div className="section flex center-h nav-upper">
          <div className="flex nav-upper-cont">
            <div className="part-60 flex center-v left">
              <a className="quote-link no-dec nav-info" href="mailto:info@kanzlei-e-lutz.de">info@kanzlei-e-lutz.de</a>
              {/* <span>Bürozeiten: Mo. - Fr. 08:30 - 17:30</span> */}
            </div>
            <div className="part-40 search-container flex center-v right">
              <a className="quote-link no-dec marg-right-1" href="tel:07112364402" >0711 - 2364402</a>
              <a className="marg-right-1" href="https://www.linkedin.com/in/elisabeth-lutz-3ab9b1265/?locale=de_DE" target="_blank">
                <img height="24px" src={LI}></img>
              </a>
              <a href="whatsapp://send?phone=+4915142800153">
                <img height="24px" src={WA}></img>
              </a>
            </div>
          </div>
        </div>
        <div className="nav-lower flex center-h">
          <div className="flex nav-lower-cont center-v center-h">
            <div className="part-40 flex spb center-v">
              <Dropdown className="argesto" id="p-dropdown" ref={this.pNav} options={options} onChange={this.productMenu} value={"Leistungen"} placeholder="Leistungen" />
              <span className="accent">|</span>
              <Link reloadDocument to="/de/pflichtanwalt">
                <span className="argesto">Pflichtanwalt</span>
              </Link>
              <span className="accent">|</span>
              <Link reloadDocument to="/de/kanzlei">
                <span className="argesto">Kanzlei</span>
              </Link>
            </div>
            <div className="part-15 flex center-v center-h">
              <Link reloadDocument className="m-right-2" to="/">
                <img height="80px" src={logo}></img>
              </Link>
            </div>
            <div className="part-40 flex center-v spb">
              <Link reloadDocument to="/de/blog">
                <span className="argesto">Blog</span>
              </Link>
              <span className="accent">|</span>
              <Link reloadDocument to="/de/formulare">
                <span className="argesto">Formulare</span>
              </Link>
              <span className="accent">|</span>
              <Link reloadDocument to="/de/kontakt">
                <span className="argesto">Kontakt</span>
              </Link>
            </div>
            <Link reloadDocument className="mob-logo m-right-2" to="/">
              <img height="80px" src={logo}></img>
            </Link>
            <div className="hamburger-cont">
              <Hamburger onToggle={this.menuClick} />
            </div>
          </div>
        </div>
        <div className={"mob-menu " + this.state.open}>
          {/*           <div className="flex center-v center-h">
            <span className="font-m active">DE</span>
            <span className="font-m m-left-1">|</span>
            <Link to="/en/home">
              <span className="font-m m-left-1">EN</span>
            </Link>
          </div> */}
          <div className="mob-nav-links flex col">
            <Link reloadDocument className="nav-link" to="/de/familienrecht">
              <span>Familienrecht</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/migrationsrecht">
              <span>Migrationsrecht</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/pflichtanwalt">
              <span>Pflichtanwalt</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/kanzlei">
              <span>Die Kanzlei</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/blog">
              <span>Unser Blog</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/glossar">
              <span>Glossar</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/formulare">
              <span>Formulare</span>
            </Link>
            <Link reloadDocument className="nav-link" to="/de/kontakt">
              <span>Kontakt</span>
            </Link>
            <div className="flex col full-width">
              <div className="flex full-width el">
                <div className="divider marg-bot-25"></div>
              </div>
              <Link reloadDocument className="nav-link" to="/de/faq">
                <span>Häufige Fragen</span>
              </Link>
              <Link reloadDocument className="nav-link" to="/de/impressum">
                <span>Impressum</span>
              </Link>
              <Link reloadDocument className="nav-link" to="/de/datenschutzerklaerung">
                <span>Datenschutz</span>
              </Link>
              <div className="flex full-width el">
                <div className="divider marg-bot-10"></div>
              </div>
              <a className="quote-link black" href="mailto:info@kanzlei-e-lutz.de">info@kanzlei-e-lutz.de</a>
              {/* <span className="nav-info">Bürozeiten: Mo. - Fr. 08:30 - 17:30</span> */}
              <a className="quote-link black" href="tel:07112364402" >0711 - 2364402</a>
              <a className="quote-link black" href="tel:015142800153" >0151 - 42800153</a>
              <div className="flex row center-v social-links-mob">
                <a className="marg-right-1" href="https://www.linkedin.com/in/elisabeth-lutz-3ab9b1265/?locale=de_DE" target="_blank">
                  <img height="48px" src={LIB}></img>
                </a>
                <a href="whatsapp://send?phone=+4915142800153">
                  <img height="48px" src={WAB}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavDE;
