import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News1 from "../../assets/blog_3.jpg";
import { Link } from "react-router-dom";

class News_3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Was kommt bei der Scheidung alles auf mich zu? Ein Leitfaden durch den Prozess
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Was kommt bei der Scheidung alles auf mich zu? Ein Leitfaden durch den Prozess"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Was kommt bei der Scheidung alles auf mich zu? Ein Leitfaden durch den Prozess</h1>
              <p className="body desc">10. März 2024</p>
              <img src={News1}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Die Entscheidung, sich scheiden zu lassen, ist eine emotionale Herausforderung, und viele Menschen
                  stehen vor der Frage: &quot;Was kommt bei der Scheidung alles auf mich zu?&quot; In diesem Blogbeitrag
                  werfen wir einen Blick auf die verschiedenen Aspekte, die während des Scheidungsprozesses in
                  Deutschland auf Sie zukommen könnten.
                </p>
                <h3>Das Trennungsjahr</h3>
                <p className="body">
                  Das Trennungsjahr ist oft der erste Schritt vor der eigentlichen Scheidung. Während dieser Zeit leben
                  die Eheleute getrennt voneinander, um zu prüfen, ob eine Versöhnung möglich ist. Es ist wichtig zu
                  beachten, dass während des Trennungsjahres keine unmittelbare Scheidung möglich ist.
                </p>
                <h3>Scheidungsantrag und Anwaltliche Vertretung</h3>
                <p className="body">
                  Der Scheidungsprozess beginnt offiziell mit dem Einreichen eines Scheidungsantrags beim
                  Familiengericht. Hier ist die Unterstützung eines Anwalts erforderlich, der den Antrag einreicht und
                  Ihre Interessen vor Gericht vertritt.</p><br></br>
                <p className="body">
                  <strong>Tipp:</strong> Wenn Sie sich mit Ihrem Ehepartner <strong>einvernehmlich</strong>, also wenn Sie beide der Scheidung
                  zustimmen, scheiden lassen, benötigen Sie nur einen Rechtsanwalt und können sich somit die Kosten
                  für einen zweiten Rechtsanwalt sparen.</p>
                <h3>Folgesachen regeln</h3>
                <p className="body">
                  Während des Scheidungsverfahrens müssen verschiedene Aspekte geregelt werden, darunter
                  Unterhalt, Sorgerecht, Umgangsrecht und die Aufteilung des Vermögens. Es ist ratsam, eine
                  einvernehmliche Lösung mit Ihrem Ehepartner zu suchen, um langwierige und kostspielige
                  Gerichtsverfahren zu vermeiden. Kommen Sie hierzu frühzeitig auf uns zu, damit Sie alle
                  Angelegenheiten frühzeitig im Blick haben.</p>
                <h3>Scheidungstermin und Gerichtsverhandlung</h3>
                <p className="body">
                  Nach dem Trennungsjahr und der Einreichung des Scheidungsantrags setzt das Gericht einen Termin
                  für die mündliche Verhandlung an. Nach Abschluss des Verfahrens erlässt das Gericht das
                  Scheidungsurteil. Dieses wird den Eheleuten und ihren Anwälten zugestellt und ist nach Zustellung
                  rechtskräftig.</p>
                <h3>Neustart nach der Scheidung</h3>
                <p className="body">
                  Die Zeit nach der Scheidung kann eine Phase des Neuanfangs bedeuten. Es ist wichtig, sich auf
                  persönlicher und wirtschaftlicher Ebene bereits im Trennungsjahr auf eigene Beine zu stellen.
                  Insbesondere sollten Sie so früh wie möglich Ihren Pflichten, wie etwa der Erwerbsobliegenheit
                  genügen. Mehr dazu hier (Da Link zum nächsten Blogartikel einfügen, <strong>siehe 3</strong>.).</p><br></br>
                <p className="body">
                  Die Scheidung ist ein komplexer Prozess, der rechtliche Herausforderungen mit sich bringt. Die
                  Unterstützung eines Rechtsanwalts kann Ihnen helfen, den Prozess so reibungslos wie möglich zu
                  gestalten.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_3;
