import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News1 from "../../assets/blog_4.jpg";
import { Link } from "react-router-dom";

class News_4 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Erwerbsobliegenheiten bei der Scheidung: Ab wann muss ich arbeiten?
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Erwerbsobliegenheiten bei der Scheidung: Ab wann muss ich arbeiten?"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Erwerbsobliegenheiten bei der Scheidung: Ab wann muss ich arbeiten?</h1>
              <p className="body desc">10. März 2024</p>
              <img src={News1}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Die Erwerbsobliegenheit ist ein Konzept im Familienrecht, das sich darauf bezieht, dass beide
                  Ehepartner nach Kräften zur Sicherung des eigenen Unterhalts beitragen sollen. In diesem Blogartikel
                  werden wir erläutern, was es damit auf sich hat und welche Auswirkungen dies bei einer Scheidung
                  haben kann.
                </p>
                <h3>Was sind Erwerbsobliegenheiten?</h3>
                <p className="body">
                  Erwerbsobliegenheiten beziehen sich auf die Verpflichtung beider Ehepartner, nach Kräften zur
                  Sicherung des eigenen Unterhalts beizutragen. Das bedeutet, dass im Rahmen einer Scheidung jeder
                  Ehepartner verpflichtet ist, angemessene Anstrengungen zu unternehmen, um seinen eigenen
                  Lebensunterhalt zu verdienen.
                </p>
                <h3>Unterhaltsverpflichtungen und ihre Bedeutung</h3>
                <p className="body">
                  Die Erwerbsobliegenheiten werden insbesondere im Zusammenhang mit Unterhaltszahlungen
                  relevant. Wenn ein Ehepartner während der Ehe weniger oder gar nicht erwerbstätig war und somit
                  einen Anspruch auf Unterhalt geltend macht, wird geprüft, ob dieser Ehepartner in der Lage ist,
                  seinen eigenen Unterhalt durch Erwerbstätigkeit zu sichern.</p>
                <h3>Berücksichtigung der individuellen Umstände</h3>
                <p className="body">
                  Bei der Beurteilung der Erwerbsobliegenheiten werden die individuellen Umstände jedes
                  Ehepartners berücksichtigt. Dazu gehören die Ausbildung, Berufserfahrung, Gesundheit und die
                  Betreuung von gemeinsamen Kindern. Es wird erwartet, dass der weniger verdienende Ehepartner
                  angemessene Anstrengungen unternimmt, um sich beruflich weiterzuentwickeln oder eine
                  angemessene Erwerbstätigkeit aufzunehmen.</p>
                <h3>Der Zeitpunkt ist maßgeblich für die Beurteilung der Erwerbsobliegenheit</h3>
                <p className="body">
                  Der Unterschied zwischen Erwerbsobliegenheiten im Trennungsjahr und nach der Scheidung liegt vor
                  allem im zeitlichen Kontext.</p>
                <h3>1. Erwerbsobliegenheit im Trennungsjahr</h3>
                <p className="body">
                  Während des Trennungsjahres, das oft der Vorbereitung auf die Scheidung dient, besteht die
                  Erwerbsobliegenheit bereits, jedoch abgeschwächt. Dies liegt daran, dass das Trennungsjahr nach
                  dem Gesetz dazu dient, die Ehe zu erhalten und nicht die Selbständigkeit der Eheleute nach der
                  Scheidung herzustellen. Beide Ehepartner haben die Verpflichtung, angemessene Anstrengungen zu
                  unternehmen, um ihren eigenen Lebensunterhalt zu sichern. Dies bedeutet, dass, selbst wenn die
                  Scheidung noch nicht abgeschlossen ist, diejenigen, die während der Ehe weniger oder gar nicht
                  erwerbstätig waren, erwartet werden, aktiv nach Möglichkeiten zur beruflichen Selbstversorgung zu
                  suchen.</p>
                <h3>2. Erwerbsobliegenheit nach der Scheidung</h3>
                <p className="body">
                  Nach der Scheidung bleibt die Erwerbsobliegenheit bestehen, dabei wird von den Ehegatten jedoch
                  deutlich mehr verlangt. Nach Ablauf des Trennungsjahrs gilt die Ehe als gescheitert. Damit geht
                  einher, dass die Eheleute insbesondere auch in finanzieller Hinsicht wieder selbständig auf eigenen
                  Beinen stehen sollen. Das geht nur, wenn jeder sich finanziell selbst versorgt und nicht mehr von
                  dem anderen abhängt. Der geschiedene Ehepartner, der weniger verdient oder nicht erwerbstätig
                  ist, wird stärker aufgefordert, angemessene Anstrengungen zu unternehmen, um seinen eigenen
                  Unterhalt zu sichern. In dieser Phase kann die genaue Ausgestaltung der Erwerbsobliegenheit durch
                  das Gericht oder im Rahmen einer einvernehmlichen Vereinbarung festgelegt werden.</p><br></br>
                <p className="body">
                  Es ist wichtig zu beachten, dass die individuellen Umstände jedes Ehepartners bei der Beurteilung der
                  Erwerbsobliegenheiten berücksichtigt werden. Faktoren wie Ausbildung, Berufserfahrung,
                  Gesundheit und die Betreuung von gemeinsamen Kindern spielen eine Rolle. Das Ziel ist es, eine
                  gerechte und realistische Einschätzung der Fähigkeiten und Möglichkeiten jedes Ehepartners zur
                  Sicherung des eigenen Unterhalts zu treffen.</p>
                <h3>Einvernehmliche Regelungen treffen</h3>
                <p className="body">
                  Um mögliche Konflikte zu vermeiden, können die Ehepartner auch einvernehmliche Regelungen
                  bezüglich der Erwerbsobliegenheiten und Unterhaltszahlungen treffen. Eine
                  Scheidungsfolgenvereinbarung kann dabei helfen, klare Vereinbarungen zu treffen und
                  Unsicherheiten zu minimieren.</p><br></br>
                <p className="body">
                  Die Erwerbsobliegenheiten spielen eine entscheidende Rolle bei der finanziellen Regelung während
                  einer Scheidung. Es ist wichtig, die individuellen Umstände zu berücksichtigen und angemessene
                  Schritte zur Sicherung des eigenen Unterhalts zu unternehmen. Eine offene Kommunikation zwischen
                  den Ehepartnern und gegebenenfalls die Unterstützung durch Fachleute wie Rechtsanwälte für
                  Familienrecht können dazu beitragen, diesen Prozess so fair und transparent wie möglich zu
                  gestalten.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_4;
