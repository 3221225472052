import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.href.indexOf("/en/") != -1) {
  root.render(
    <BrowserRouter>
{/*       <CookieConsent
        location="bottom"
        buttonText="Got it"
        cookieName="eli"
        style={{ background: "#ffffff", color: "#000000" }}
        buttonStyle={{ color: "#ffffff", background: "#385956", fontSize: "15px", padding:"10px 20px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent> */}
      <App />
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
{/*       <CookieConsent
        location="bottom"
        buttonText="Verstanden"
        cookieName="eli"
        style={{ background: "#ffffff", color: "#000000" }}
        buttonStyle={{ color: "#ffffff", background: "#385956", fontSize: "15px", padding:"10px 20px" }}
        expires={150}
      >
        Diese Webseite verwendet Cookies um die Benutzererfahrung zu verbessern.{" "}
      </CookieConsent> */}
      <App />
    </BrowserRouter>
  );
}
