import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News7 from "../../assets/blog_7.jpg";
import { Link } from "react-router-dom";

class News_7 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Wann steht mir Trennungsunterhalt zu?
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Wann steht mir Trennungsunterhalt zu?"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Wann steht mir Trennungsunterhalt zu?</h1>
              <p className="body desc">08.07.24</p>
              <img src={News7}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Trennungsunterhalt ist ein zentrales Thema im deutschen Familienrecht, das die finanzielle
                  Absicherung des wirtschaftlich schwächeren Ehepartners nach einer Trennung regelt. Der
                  Trennungsunterhalt dient dazu, den Lebensstandard des bedürftigen Ehepartners während
                  der Trennungszeit zu sichern, bis die Scheidung rechtskräftig wird.
                </p>
                <h3>Gesetzliche Grundlagen</h3>
                <p className="body">
                  Die rechtlichen Regelungen zum Trennungsunterhalt finden sich im Bürgerlichen
                  Gesetzbuch (BGB), insbesondere in den §§ 1361 ff. BGB. Danach hat der weniger
                  verdienende oder nicht erwerbstätige Ehepartner Anspruch auf angemessenen Unterhalt
                  vom besser verdienenden Partner.</p>
                <h3>Voraussetzungen für den Trennungsunterhalt</h3>
                <p className="body">
                  Trennung: Eine tatsächliche räumliche und wirtschaftliche Trennung der Ehegatten muss
                  vorliegen. Dies bedeutet, dass die Ehegatten nicht mehr in einer ehelichen
                  Lebensgemeinschaft leben.<br></br><br></br>
                  Bedarf: Die Höhe des Trennungsunterhalts richtet sich nach den ehelichen
                  Lebensverhältnissen und den jeweiligen Einkommens- und Vermögensverhältnissen der
                  Ehegatten. Bei der Berechnung wird das sogenannte &quot;bereinigte Nettoeinkommen&quot; beider
                  Ehepartner herangezogen.<br></br><br></br>
                  Bedürftigkeit: Der unterhaltsberechtigte Ehegatte muss bedürftig sein. Bedürftigkeit liegt vor,
                  wenn der Ehegatte nicht in der Lage ist, seinen Lebensunterhalt selbst zu bestreiten.<br></br><br></br>
                  Leistungsfähigkeit: Der unterhaltspflichtige Ehegatte muss leistungsfähig sein. Dies
                  bedeutet, dass er in der Lage ist, den geforderten Unterhalt zu zahlen, ohne seinen eigenen
                  angemessenen Unterhalt zu gefährden.</p>
                <h3>Höhe des Trennungsunterhalts</h3>
                <p className="body">
                  Dabei sind diverse Faktoren zu berücksichtigen, wie etwa:</p>
                <br></br>
                <ul className="body">
                  <li>Einkommen aus Erwerbstätigkeit</li>
                  <li>Mieteinnahmen</li>
                  <li>Kapitalerträge</li>
                  <li>Schulden und sonstige Verbindlichkeiten</li>
                  <li>Kosten für die Krankenversicherung</li>
                </ul>
                <h3>Sonderfälle</h3>
                <p className="body">
                  Verwirkung: Der Anspruch auf Trennungsunterhalt kann verwirkt werden, wenn der
                  bedürftige Ehegatte beispielsweise eine neue feste Partnerschaft eingeht oder sich grob
                  unbillig verhält.<br></br><br></br>
                  Eigene Erwerbstätigkeit: Der bedürftige Ehegatte muss in der Regel auch versuchen, seinen
                  Unterhalt durch eigene Erwerbstätigkeit zu sichern, soweit dies zumutbar ist.
                </p>
                <h3>Dauer des Trennungsunterhalts</h3>
                <p className="body">
                  Der Trennungsunterhalt wird grundsätzlich bis zur Rechtskraft der Scheidung gewährt. Nach
                  der Scheidung kann der bedürftige Ehegatte unter bestimmten Voraussetzungen Anspruch
                  auf nachehelichen Unterhalt haben, der jedoch anderen Regelungen unterliegt.
                </p>
                <h3>Fazit</h3>
                <p className="body">
                  Trennungsunterhalt ist ein wichtiger Bestandteil des deutschen Familienrechts und dient dem
                  Schutz des wirtschaftlich schwächeren Ehepartners während der Trennungsphase. Die
                  genaue Berechnung und Durchsetzung kann komplex sein und erfordert oft die
                  Unterstützung eines Anwalts, um eine faire und angemessene Regelung zu erreichen.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_7;
