import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class FAQ extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - FAQ</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - FAQ" />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first intro center-h less-bot imp">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">FAQ</h2>
            </div>
            <div className="flex col">
              <h2>Welche Kosten entstehen für Ihre Rechtsberatung?</h2>
              <p className="body">
                Die Kosten für einen Rechtsanwalt nach dem Rechtsanwaltsvergütungsgesetz (RVG)
                hängen von mehreren Faktoren ab, darunter der Gegenstandswert und die Art der
                anwaltlichen Tätigkeit. Das RVG legt fest, welche Gebühren für bestimmte Tätigkeiten
                anfallen.<br></br><br></br>
                Hier ist ein Überblick über die Kosten:
                <ol className="body"><li><strong>Beratungskosten:</strong> Für eine Erstberatung fallen Gebühren i.H.v. 190 Euro
                  zuzüglich Mehrwertsteuer, also insg. 226,10 Euro an.</li><br></br>
                  <li><strong>Gegenstandswertabhängige Gebühren:</strong> Bei gerichtlichen Verfahren werden die
                    Gebühren auf Basis des Gegenstandswerts berechnet. Der Gegenstandswert
                    richtet sich nach dem wirtschaftlichen Interesse der Parteien. Hier einige
                    Beispiele:</li>
                  <ul className="body"><li><strong>Scheidung:</strong> Der Gegenstandswert bei einer Scheidung setzt sich aus dem
                    dreifachen monatlichen Nettoeinkommen der Ehegatten zusammen. Bei
                    einem Nettoeinkommen von 3.000 Euro würde der Gegenstandswert
                    somit 9.000 Euro betragen.</li>
                    <li><strong>Unterhalt:</strong> Der Gegenstandswert bei Unterhaltsangelegenheiten ergibt
                      sich aus dem Jahresbetrag der geforderten Unterhaltszahlungen.</li>
                    <li><strong>Niederlassungserlaubnis:</strong> Der Gegenstandswert beträgt 5.000 Euro.</li></ul>
                  <p className="body">
                    <br></br>
                    <strong>Gebührenarten:</strong>
                    <br></br><br></br>
                  </p>
                  <ul className="body"><li><strong>Verfahrensgebühr:</strong> Diese fällt an, wenn der Anwalt ein gerichtliches
                    Verfahren einleitet und beträgt einen bestimmten Prozentsatz des
                    Gegenstandswerts.</li>
                    <li><strong>Terminsgebühr:</strong> Diese wird berechnet, wenn der Anwalt an einer
                      Gerichtsverhandlung teilnimmt.</li>
                    <li><strong>Einigungsgebühr:</strong> Wenn der Anwalt eine Einigung herbeiführt, wird diese
                      Gebühr fällig.</li></ul>
                  <p className="body">
                    <br></br>
                    Ein konkretes Beispiel:
                    Bei einem Scheidungsverfahren mit einem Gegenstandswert von 9.000 Euro
                    könnten die Kosten für den Anwalt folgendermaßen aussehen:
                  </p>
                  <ul className="body"><li><strong>Verfahrensgebühr:</strong> 725,20 Euro</li>
                    <li><strong>Terminsgebühr:</strong> 669,60 Euro</li>
                    <li><strong>Einigungsgebühr:</strong> 558,00 Euro (wenn eine Einigung erreicht wird)</li>
                    <li><strong>Auslagenpauschale:</strong> 20 Euro</li>
                    <li><strong>Mehrwertsteuer (19%):</strong> auf die Gesamtsumme: 305,96 Euro</li></ul>
                  <br></br>
                  <li><strong>Abrechnung nach Stundenhonorar:</strong> Eine Abrechnung des Rechtsanwalts nach
                    Stundenhonorar erfolgt auf Grundlage der tatsächlich geleisteten Arbeitszeit.
                    Dies ist eine alternative Vergütungsform zum Rechtsanwaltsvergütungsgesetz
                    (RVG), die in einem individuellen Vertrag zwischen dem Anwalt und dem
                    Mandanten vereinbart wird. Durch eine Abrechnung nach Stundenhonorar
                    haben Sie den Vorteil, eine genaue Übersicht über unsere geleisteten

                    Tätigkeiten und die dafür aufgewendete Zeit zu erhalten. Wir treffen mit Ihnen
                    vor Beginn unserer Tätigkeit eine klare Vereinbarung über den Stundensatz und
                    die Art der Tätigkeitsdokumentation im Voraus, damit Sie wissen, welche Kosten
                    auf Sie zukommen können.</li>
                </ol><br></br>
              </p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default FAQ;
