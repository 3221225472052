import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import { FaBook } from "react-icons/fa";

class Glossar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      glossar_term: "Default",
      error: false,
      success: false,
    };
    this.form_name = React.createRef();
    this.form_mail = React.createRef();
    this.form_msg = React.createRef();
    this.form_phone = React.createRef();
    this.form_gdpr = React.createRef();
    this.filterCont = React.createRef();
    this.definition = React.createRef();
    this.glossar_start = React.createRef();
    this.filterToggle = this.filterToggle.bind(this);
  }

  lookupTerm = (e) => {
    let term = e.target.innerHTML;
    if (this.filterCont.current.classList.contains("open")) {
      this.filterCont.current.classList.remove("open");
      this.ScrollDefinition();
    }
    e.preventDefault();
    this.setState({
      glossar_term: term,
    });
  };

  filterToggle(e) {
    if (this.filterCont.current.classList.contains("open")) {
      this.filterCont.current.classList.remove("open");
      this.ScrollDefinition();
    } else {
      this.filterCont.current.classList.add("open");
      this.ScrollGlossar();
    }
    e.preventDefault();
  }

  sendMail = (e) => {
    if (
      !this.form_gdpr.current.checked ||
      !this.form_mail.current.value ||
      !this.form_msg.current.value
    ) {
      this.setState({
        error: true,
      });
    } else {
      this.form_gdpr.current.checked = false;
      this.setState({
        error: false,
        success: true,
      });
      let html =
        "<h1>" +
        "Contact Form Submission Values<br><br>" +
        "</h1><p>Name: " +
        this.form_name.current.value +
        "</p>" +
        "<p>E-Mail: " +
        this.form_mail.current.value +
        "</p>" +
        this.form_msg.current.value +
        "</p>" +
        this.form_phone.current.value +
        "</p>";
      fetch("https://hooks.zapier.com/hooks/catch/7306825/2yi9ti5/", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ html }),
      });
      this.form_name.current.value = "";
      this.form_mail.current.value = "";
      this.form_msg.current.value = "";
      this.form_phone.current.value = "";
    }
  };

  ScrollGlossar = () => {
    let element = this.glossar_start.current;
    let headerOffset = 140;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "instant",
    });
  };

  ScrollDefinition = () => {
    let element = this.definition.current;
    let headerOffset = 140;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "instant",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Glossar</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Glossar" />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="hero home formulare less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <h2 className="hero-header argesto">Glossar</h2>
            </div>
          </div>
        </div>
        <div
          className="section flex first intro center-h less-bot"
        >
          <div id="formular" className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Glossar</h2>
            </div>
            <div className="flex full-width">
              <div ref={this.filterCont} className="third-container third glossar">
                <p ref={this.glossar_start} className="argesto glossar-letter">A</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Abstammung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Adoption</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Alleinsorge</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Anhörung</a>
                <p className="argesto glossar-letter">B</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Begleiteter Umgang</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Beistandschaft</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Besuchsrecht (Umgangsrecht)</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Betreuungsunterhalt</a>
                <p className="argesto glossar-letter">E</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Ehegattenunterhalt</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Eheaufhebung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Ehevertrag</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Einvernehmliche Scheidung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Elterliche Sorge</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Elterngeld</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Elternzeit</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Ergänzungspflegschaft</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Erziehungsbeistand</a>
                <p className="argesto glossar-letter">F</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Familiengericht</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Familienpflege</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Familienrecht</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Foster Care (Pflege)</a>
                <p className="argesto glossar-letter">G</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Gemeinsame Sorge</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Gütertrennung</a>
                <p className="argesto glossar-letter">H</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Hausratsteilung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Haushaltsgegenstände</a>
                <p className="argesto glossar-letter">K</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Kindergeld</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Kindesmissbrauch</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Kindesunterhalt</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Kindeswohl</a>
                <p className="argesto glossar-letter">M</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Mediation</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Mutterpass</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Mutterschaft</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Mutterschaftsgeld</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Mutterschutz</a>
                <p className="argesto glossar-letter">N</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Nachehelicher Unterhalt</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Namensrecht</a>
                <p className="argesto glossar-letter">P</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Partnerschaftsvertrag</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Pflegschaft</a>
                <p className="argesto glossar-letter">R</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Residenzmodell</a>
                <p className="argesto glossar-letter">S</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Scheidung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Scheidungsantrag</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Scheidungsfolgenvereinbarung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Sorgerecht</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Sorgerechtsverfügung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Stiefkindadoption</a>
                <p className="argesto glossar-letter">T</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Trennungsjahr</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Trennungsunterhalt</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Trennungsvereinbarung</a>
                <p className="argesto glossar-letter">U</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Umgangspflegschaft</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Umgangsrecht</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Unterhalt</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Unterhaltsvorschuss</a>
                <p className="argesto glossar-letter">V</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Vaterschaftsanerkennung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Vaterschaftsanfechtung</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Vermögensausgleich</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Vermögenssorge</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Versorgungsausgleich</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Vorsorgevollmacht</a>
                <p className="argesto glossar-letter">W</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Wechselmodell</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Wohlverhaltenspflicht</a>
                <p className="argesto glossar-letter">Z</p>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Zugewinnausgleich</a>
                <a onClick={this.lookupTerm} className="argesto glossar-term">Zwangsvollstreckung</a>
              </div>
              <div className="two-thirds">
                <a
                  href="#"
                  ref={this.definition}
                  onClick={this.filterToggle}
                  className="default glossar glossar-opener">
                  A - Z
                </a>
                {this.state.glossar_term == "Abstammung" ?
                  <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Abstammung</h3>
                    <p className="argesto glossar-desc">Die rechtliche und biologische Beziehung eines Kindes zu seinen Eltern.</p>
                  </div> : this.state.glossar_term == "Adoption" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Adoption</h3>
                    <p className="argesto glossar-desc">Die rechtliche Annahme eines Kindes, wodurch ein neues, dauerhaftes Eltern-Kind-
                      Verhältnis geschaffen wird. Die rechtlichen Bindungen zur leiblichen Familie werden
                      meist vollständig aufgelöst.</p>
                  </div> : this.state.glossar_term == "Alleinsorge" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Alleinsorge</h3>
                    <p className="argesto glossar-desc">Wenn nur ein Elternteil das Sorgerecht für ein Kind hat und somit allein über wichtige
                      Angelegenheiten des Kindes entscheiden darf.</p>
                  </div> : this.state.glossar_term == "Anhörung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Anhörung</h3>
                    <p className="argesto glossar-desc">Ein gerichtliches Verfahren, bei dem die beteiligten Parteien (z.B. Eltern, Kinder) gehört
                      werden, um deren Standpunkte zu berücksichtigen.</p>
                  </div> : this.state.glossar_term == "Begleiteter Umgang" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Begleiteter Umgang</h3>
                    <p className="argesto glossar-desc">Bei den Treffen ist eine dritte Person dabei. Das kann zum Beispiel ein(e)
                      Mitarbeiter(in) des Jugendamtes oder einer Erziehungsberatungsstelle sein.
                      Der begleitete Umgang kann auch vom Familiengericht angeordnet werden.</p>
                  </div> : this.state.glossar_term == "Beistandschaft" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Beistandschaft</h3>
                    <p className="argesto glossar-desc">Die Beistandschaft bezeichnet die Unterstützung des Jugendamtes für einen Vater
                      oder eine Mutter, der/die alleine für sein/ihr Kind sorgeberechtigt ist. Der Beistand
                      hilft bei der Durchsetzung von Unterhaltsansprüchen für das Kind sowie bei der
                      Feststellung der Vaterschaft.</p>
                  </div> : this.state.glossar_term == "Besuchsrecht (Umgangsrecht)" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Besuchsrecht (Umgangsrecht)</h3>
                    <p className="argesto glossar-desc">Das Recht eines Elternteils, das nicht das Sorgerecht hat, Zeit mit seinem Kind zu
                      verbringen.</p>
                  </div> : this.state.glossar_term == "Betreuungsunterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Betreuungsunterhalt</h3>
                    <p className="argesto glossar-desc">Unterhalt, der einem Elternteil zusteht, der wegen der Betreuung eines gemeinsamen
                      Kindes nicht oder nur eingeschränkt arbeiten kann.</p>
                  </div> : this.state.glossar_term == "Ehegattenunterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Ehegattenunterhalt</h3>
                    <p className="argesto glossar-desc">Unterhaltszahlungen, die ein Ehegatte an den anderen nach der Trennung oder
                      Scheidung leisten muss, um den Lebensstandard des bedürftigen Ehepartners zu
                      sichern.</p>
                  </div> : this.state.glossar_term == "Eheaufhebung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Eheaufhebung</h3>
                    <p className="argesto glossar-desc">Die gerichtliche Aufhebung einer Ehe aufgrund von schwerwiegenden Mängeln bei der
                      Eheschließung (z.B. Doppelehe, Verwandtschaft).</p>
                  </div> : this.state.glossar_term == "Ehevertrag" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Ehevertrag</h3>
                    <p className="argesto glossar-desc">Ein Vertrag zwischen Ehepartnern, der die finanziellen und rechtlichen Aspekte der
                      Ehe regelt, oft inklusive der Vermögensaufteilung im Falle einer Scheidung.</p>
                  </div> : this.state.glossar_term == "Einvernehmliche Scheidung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Einvernehmliche Scheidung</h3>
                    <p className="argesto glossar-desc">Eine Scheidung, bei der sich beide Ehepartner über alle wesentlichen Punkte (z.B.
                      Unterhalt, Sorgerecht, Vermögensaufteilung) einig sind.</p>
                  </div> : this.state.glossar_term == "Elterliche Sorge" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Elterliche Sorge</h3>
                    <p className="argesto glossar-desc">Siehe Sorgerecht.</p>
                  </div> : this.state.glossar_term == "Elterngeld" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Elterngeld</h3>
                    <p className="argesto glossar-desc">Eltern, die auf Zeit aus ihrem Beruf aussteigen, um ihre Kinder zu betreuen, erhalten
                      Elterngeld als staatliche Unterstützung. Es soll ein vorübergehendes Ausscheiden aus
                      dem Beruf ermöglichen, ohne dass die Familie starke finanzielle Einbußen hinnehmen
                      muss. Die Höhe des Elterngeldes richtet sich nach dem Einkommen der Eltern.</p>
                  </div> : this.state.glossar_term == "Elternzeit" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Elternzeit</h3>
                    <p className="argesto glossar-desc">Die Elternzeit bezeichnet den gesetzlich geregelten Anspruch auf unbezahlte
                      Freistellung von der Arbeit nach der Geburt eines Kindes. So können sich die Eltern in
                      den ersten drei Jahren besonders intensiv um ihr leibliches, adoptiertes oder in Obhut
                      genommenes Kind kümmern. Die Sorge um den Arbeitsplatz entfällt: Während der
                      Elternzeit darf man nicht gekündigt werden, und danach hat man Anspruch auf die alte
                      oder eine gleichwertige Stelle.</p>
                  </div> : this.state.glossar_term == "Ergänzungspflegschaft" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Ergänzungspflegschaft</h3>
                    <p className="argesto glossar-desc">Das Sorgerecht der Eltern für ihre minderjährigen Kinder bedeutet, dass die Eltern
                      rechtlich im Namen ihrer Kinder handeln können (und müssen): Kinder werden durch
                      ihre Eltern gesetzlich vertreten. Es kann passieren, dass Eltern aus rechtlichen
                      Gründen daran gehindert sind, ihre Kinder zu vertreten (zum Beispiel, wenn sie und die
                      Kinder gemeinsam etwas erben). Es kann auch sein, dass das Familiengericht das
                      Sorgerecht der Eltern beschränkt. Auch dann können die Eltern ihr Kind in bestimmten
                      Angelegenheiten (zum Beispiel in der Aufenthaltsbestimmung) nicht vertreten.</p>
                  </div> : this.state.glossar_term == "Erziehungsbeistand" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Erziehungsbeistand</h3>
                    <p className="argesto glossar-desc">Wenn Kinder und Jugendliche große Probleme im Alltag, in der Schule und zu Hause
                      haben, kann für sie ein Erziehungsbeistand eingeschaltet werden. Diese pädagogische
                      Fachkraft arbeitet mit den Kindern und Jugendlichen. Gemeinsam mit dem Kind oder
                      dem/der Jugendlichen, den Eltern und einer Mitarbeiterin des Jugendamtes werden im
                      Vorfeld der Hilfe ihre Ziele, Inhalte und ihre Dauer festgelegt. Der Erziehungsbeistand
                      kommt vom Jugendamt oder einem freien Träger wie der Caritas.</p>
                  </div> : this.state.glossar_term == "Familiengericht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Familiengericht</h3>
                    <p className="argesto glossar-desc">Ein spezialisiertes Gericht, das für familienrechtliche Angelegenheiten zuständig ist.</p>
                  </div> : this.state.glossar_term == "Familienpflege" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Familienpflege</h3>
                    <p className="argesto glossar-desc">Die Familienpflege unterstützt Familien, in denen die Mutter oder der Vater durch
                      Krankheit oder andere Notsituationen ausfallen. Sie kann in Anspruch genommen
                      werden, wenn mindestens ein Kind unter 12 Jahren (bei manchen Krankenkassen unter
                      14 Jahren) im Haushalt lebt oder eine Behinderung hat und die Betreuung nicht mehr
                      gewährleistet ist. Beispielsweise, weil die Mutter wegen einer Risikoschwangerschaft
                      liegen muss.</p>
                  </div> : this.state.glossar_term == "Familienrecht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Familienrecht</h3>
                    <p className="argesto glossar-desc">Im Familienrecht sind die gesetzlichen Regelungen zu Ehe, Lebenspartnerschaft,
                      Familie und Verwandtschaft festgeschrieben. Darüber hinaus regelt es aber auch die
                      außerhalb der Verwandtschaft bestehenden gesetzlichen Vertretungen wie
                      Vormundschaft, Pflegschaft und rechtliche Betreuung. Zentrale Rechtsgrundlage bildet
                      Artikel 6 des Grundgesetzes. Darin ist als Aufgabe des Staates festgelegt, die Ehe und
                      Familie zu schützen und zu fördern, ohne die Familienautonomie zu stören.</p>
                  </div> : this.state.glossar_term == "Foster Care (Pflege)" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Foster Care (Pflege)</h3>
                    <p className="argesto glossar-desc">Eine Form der Unterbringung von Kindern, die vorübergehend oder dauerhaft nicht bei
                      ihren leiblichen Eltern leben können, in einer Pflegefamilie oder einem Heim.</p>
                  </div> : this.state.glossar_term == "Gemeinsame Sorge" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Gemeinsame Sorge</h3>
                    <p className="argesto glossar-desc">Wenn beide Elternteile gemeinsam das Sorgerecht für ihr Kind ausüben und
                      gemeinsam über wichtige Angelegenheiten entscheiden.</p>
                  </div> : this.state.glossar_term == "Gütertrennung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Gütertrennung</h3>
                    <p className="argesto glossar-desc">Ein ehelicher Güterstand, bei dem die Vermögen der Ehepartner getrennt bleiben. Im
                      Falle einer Scheidung gibt es keinen Zugewinnausgleich.</p>
                  </div> : this.state.glossar_term == "Hausratsteilung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Hausratsteilung</h3>
                    <p className="argesto glossar-desc">Die Aufteilung der Haushaltsgegenstände bei Trennung oder Scheidung.</p>
                  </div> : this.state.glossar_term == "Haushaltsgegenstände" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Haushaltsgegenstände</h3>
                    <p className="argesto glossar-desc">Gegenstände des täglichen Gebrauchs, die im gemeinsamen Haushalt der Ehegatten
                      genutzt werden und bei der Scheidung aufgeteilt werden müssen.</p>
                  </div> : this.state.glossar_term == "Kindergeld" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Kindergeld</h3>
                    <p className="argesto glossar-desc">Staatliche finanzielle Unterstützung für Eltern zur Deckung der Kosten für die
                      Betreuung und Erziehung ihrer Kinder.</p>
                  </div> : this.state.glossar_term == "Kindesmissbrauch" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Kindesmissbrauch</h3>
                    <p className="argesto glossar-desc">Die vorsätzliche Misshandlung oder Vernachlässigung eines Kindes durch eine Person,
                      die für das Kind verantwortlich ist.</p>
                  </div> : this.state.glossar_term == "Kindesunterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Kindesunterhalt</h3>
                    <p className="argesto glossar-desc">Unterhaltszahlungen, die ein Elternteil an den anderen Elternteil oder eine andere
                      Betreuungsperson leistet, um den Lebensunterhalt des gemeinsamen Kindes zu
                      sichern.</p>
                  </div> : this.state.glossar_term == "Kindeswohl" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Kindeswohl</h3>
                    <p className="argesto glossar-desc">Das zentrale Prinzip im Familienrecht, das sicherstellen soll, dass alle Entscheidungen
                      im besten Interesse des Kindes getroffen werden.</p>
                  </div> : this.state.glossar_term == "Mediation" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Mediation</h3>
                    <p className="argesto glossar-desc">Ein freiwilliges Verfahren zur außergerichtlichen Beilegung von Streitigkeiten, bei dem
                      ein neutraler Dritter (Mediator) die Parteien unterstützt, eine einvernehmliche Lösung
                      zu finden.</p>
                  </div> : this.state.glossar_term == "Mutterpass" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Mutterpass</h3>
                    <p className="argesto glossar-desc">Der Mutterpass begleitet die werdende Mutter während der Schwangerschaft und,
                      wenn das Kind da ist, bei den Nachuntersuchungen. In der Regel erhält ihn die
                      Schwangere nach der Feststellung der Schwangerschaft von ihrer Frauenärztin / ihrem
                      Frauenarzt. Der Mutterpass beinhaltet alle Informationen, die für die medizinische
                      Betreuung von Mutter und Kind wichtig sind. Dazu gehören etwa die Blutgruppe, das
                      Gewicht und weitere gesundheitliche Angaben über die Mutter. Zudem werden die in
                      den Untersuchungen gesammelten Daten über das Kind festgehalten.</p>
                  </div> : this.state.glossar_term == "Mutterschaft" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Mutterschaft</h3>
                    <p className="argesto glossar-desc">Die rechtliche und biologische Beziehung zwischen einer Mutter und ihrem Kind.</p>
                  </div> : this.state.glossar_term == "Mutterschaftsgeld" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Mutterschaftsgeld</h3>
                    <p className="argesto glossar-desc">Schwangere, die als Angestellte arbeiten, erhalten sechs Wochen vor und acht Wochen
                      (bei Früh- und Mehrlingsgeburten: zwölf Wochen) nach der Geburt ihres Babys
                      Mutterschaftsgeld. In dieser Zeit dürfen sie nicht arbeiten. Das Mutterschaftsgeld
                      entspricht in der Regel der Höhe des letzten Nettogehalts. Es wird anteilig von der
                      Krankenkasse und dem Arbeitgeber übernommen.</p>
                  </div> : this.state.glossar_term == "Mutterschutz" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Mutterschutz</h3>
                    <p className="argesto glossar-desc">Die Bestimmungen zum Mutterschutz sind im Mutterschutzgesetz (MuSchG)
                      festgehalten. Dieses gilt für Frauen, die zum Zeitpunkt ihrer Schwangerschaft in einem
                      Arbeitsverhältnis stehen. Es schützt schwangere Frauen und die Mütter vor Kündigung
                      und dient dem Schutz von Mutter und Kind vor Gefahren am Arbeitsplatz. Es besteht
                      ein Anrecht auf eine Mutterschutzfrist von 14 Wochen – sechs Wochen vor und acht
                      Wochen nach der Entbindung. In dieser Zeit dürfen Frauen nicht beschäftigt werden.</p>
                  </div> : this.state.glossar_term == "Nachehelicher Unterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Nachehelicher Unterhalt</h3>
                    <p className="argesto glossar-desc">Unterhaltszahlungen, die nach der Scheidung geleistet werden, um den
                      Lebensstandard des bedürftigen Ehepartners zu sichern.</p>
                  </div> : this.state.glossar_term == "Namensrecht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Namensrecht</h3>
                    <p className="argesto glossar-desc">Die gesetzlichen Regelungen zur Führung des Familiennamens von Ehepartnern und
                      Kindern.</p>
                  </div> : this.state.glossar_term == "Partnerschaftsvertrag" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Partnerschaftsvertrag</h3>
                    <p className="argesto glossar-desc">Ein Vertrag zwischen Lebenspartnern, der die rechtlichen und finanziellen Aspekte
                      ihrer Partnerschaft regelt, ähnlich einem Ehevertrag.</p>
                  </div> : this.state.glossar_term == "Pflegschaft" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Pflegschaft</h3>
                    <p className="argesto glossar-desc">Eine gerichtliche Maßnahme, bei der eine Person (Pfleger) die rechtliche Vertretung
                      und Betreuung eines Kindes oder eines Erwachsenen übernimmt, wenn die Eltern oder
                      der Betroffene selbst dazu nicht in der Lage sind.</p>
                  </div> : this.state.glossar_term == "Residenzmodell" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Residenzmodell</h3>
                    <p className="argesto glossar-desc">Ein Betreuungsmodell, bei dem das Kind überwiegend bei einem Elternteil lebt und der
                      andere Elternteil ein Umgangsrecht hat.</p>
                  </div> : this.state.glossar_term == "Scheidung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Scheidung</h3>
                    <p className="argesto glossar-desc">Die rechtliche Auflösung einer Ehe durch ein Gerichtsurteil.</p>
                  </div> : this.state.glossar_term == "Scheidungsantrag" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Scheidungsantrag</h3>
                    <p className="argesto glossar-desc">Der formelle Antrag auf Scheidung, der bei Gericht eingereicht wird.</p>
                  </div> : this.state.glossar_term == "Scheidungsfolgenvereinbarung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Scheidungsfolgenvereinbarung</h3>
                    <p className="argesto glossar-desc">Ein Vertrag zwischen Ehepartnern, der die rechtlichen und finanziellen Folgen einer
                      Scheidung regelt.</p>
                  </div> : this.state.glossar_term == "Sorgerecht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Sorgerecht</h3>
                    <p className="argesto glossar-desc">Das Recht und die Pflicht der Eltern, für das Wohl und die Entwicklung ihres Kindes zu
                      sorgen. Umfasst die Personensorge (z.B. Pflege, Erziehung) und die Vermögenssorge
                      (Verwaltung des Kindesvermögens).</p>
                  </div> : this.state.glossar_term == "Sorgerechtsverfügung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Sorgerechtsverfügung</h3>
                    <p className="argesto glossar-desc">Eine Verfügung, in der Eltern festlegen, wer im Falle ihres Todes oder ihrer
                      Handlungsunfähigkeit das Sorgerecht für ihre Kinder übernehmen soll.</p>
                  </div> : this.state.glossar_term == "Stiefkindadoption" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Stiefkindadoption</h3>
                    <p className="argesto glossar-desc">Die Adoption eines Kindes durch den neuen Ehepartner eines Elternteils.</p>
                  </div> : this.state.glossar_term == "Trennungsjahr" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Trennungsjahr</h3>
                    <p className="argesto glossar-desc">Der Zeitraum von einem Jahr, den Ehegatten in Deutschland getrennt leben müssen,
                      bevor sie die Scheidung einreichen können.</p>
                  </div> : this.state.glossar_term == "Trennungsunterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Trennungsunterhalt</h3>
                    <p className="argesto glossar-desc">Unterhaltszahlungen, die ein Ehepartner während der Trennungsphase bis zur
                      rechtskräftigen Scheidung an den anderen leisten muss.</p>
                  </div> : this.state.glossar_term == "Trennungsvereinbarung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Trennungsvereinbarung</h3>
                    <p className="argesto glossar-desc">Eine Vereinbarung zwischen den Ehepartnern, die die Bedingungen der Trennung
                      regelt, einschließlich Unterhalt, Sorgerecht und Vermögensaufteilung.</p>
                  </div> : this.state.glossar_term == "Umgangspflegschaft" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Umgangspflegschaft</h3>
                    <p className="argesto glossar-desc">Eine gerichtliche Maßnahme, bei der ein Pfleger bestellt wird, um die Durchführung
                      des Umgangsrechts zu gewährleisten.</p>
                  </div> : this.state.glossar_term == "Umgangsrecht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Umgangsrecht</h3>
                    <p className="argesto glossar-desc">Siehe Besuchsrecht.</p>
                  </div> : this.state.glossar_term == "Unterhalt" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Unterhalt</h3>
                    <p className="argesto glossar-desc">Zahlungen, die eine Person an eine andere leistet, um deren Lebensunterhalt zu
                      sichern. Im Familienrecht umfasst dies Kindesunterhalt, Ehegattenunterhalt und
                      Betreuungsunterhalt.</p>
                  </div> : this.state.glossar_term == "Unterhaltsvorschuss" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Unterhaltsvorschuss</h3>
                    <p className="argesto glossar-desc">Kindern von Alleinerziehenden steht ein Unterhalt zu. Diesen muss der andere
                      Elternteil übernehmen. Falls der Vater oder die Mutter nicht oder zu wenig bezahlt,
                      springt das Jugendamt mit dem Unterhaltsvorschuss ein. Das Jugendamt bezahlt diese
                      Sozialleistung auf Antrag.</p>
                  </div> : this.state.glossar_term == "Vaterschaftsanerkennung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Vaterschaftsanerkennung</h3>
                    <p className="argesto glossar-desc">Die freiwillige Anerkennung der Vaterschaft durch den Vater eines nichtehelichen
                      Kindes.</p>
                  </div> : this.state.glossar_term == "Vaterschaftsanfechtung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Vaterschaftsanfechtung</h3>
                    <p className="argesto glossar-desc">Ein gerichtliches Verfahren, um die rechtliche Vaterschaft anzufechten.</p>
                  </div> : this.state.glossar_term == "Vermögensausgleich" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Vermögensausgleich</h3>
                    <p className="argesto glossar-desc">Die Verteilung des während der Ehe erworbenen Vermögens im Falle einer Scheidung.</p>
                  </div> : this.state.glossar_term == "Vermögenssorge" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Vermögenssorge</h3>
                    <p className="argesto glossar-desc">Teil der elterlichen Sorge, der die Verwaltung des Vermögens des Kindes umfasst.</p>
                  </div> : this.state.glossar_term == "Versorgungsausgleich" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Versorgungsausgleich</h3>
                    <p className="argesto glossar-desc">Der Ausgleich der während der Ehezeit erworbenen Rentenansprüche zwischen den
                      Ehepartnern im Falle einer Scheidung.</p>
                  </div> : this.state.glossar_term == "Vorsorgevollmacht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Vorsorgevollmacht</h3>
                    <p className="argesto glossar-desc">Eine Vollmacht, die es einer Person ermöglicht, im Voraus eine andere Person zu
                      bevollmächtigen, Entscheidungen in ihrem Namen zu treffen, falls sie dazu nicht mehr
                      in der Lage ist.</p>
                  </div> : this.state.glossar_term == "Wechselmodell" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Wechselmodell</h3>
                    <p className="argesto glossar-desc">Ein Betreuungsmodell, bei dem das Kind abwechselnd bei beiden Elternteilen lebt und
                      beide Elternteile gleichberechtigt an der Erziehung beteiligt sind.</p>
                  </div> : this.state.glossar_term == "Wohlverhaltenspflicht" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Wohlverhaltenspflicht</h3>
                    <p className="argesto glossar-desc">Die Pflicht der Eltern, das Kindeswohl zu wahren und Konflikte, die das Kind betreffen,
                      zu vermeiden oder zu minimieren.</p>
                  </div> : this.state.glossar_term == "Zugewinnausgleich" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Zugewinnausgleich</h3>
                    <p className="argesto glossar-desc">Ein Ausgleich des Vermögenszuwachses, der während der Ehezeit erzielt wurde, im
                      Falle einer Scheidung. Der Ehepartner, der während der Ehe weniger Vermögen
                      hinzugewonnen hat, hat Anspruch auf die Hälfte des Unterschieds.</p>
                  </div> : this.state.glossar_term == "Zwangsvollstreckung" ? <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Zwangsvollstreckung</h3>
                    <p className="argesto glossar-desc">Das gerichtliche Verfahren zur Durchsetzung von Unterhalts- oder
                      Umgangsrechtsansprüchen, wenn der Verpflichtete seinen Pflichten nicht nachkommt.
                      Dieses Glossar bietet eine umfassende Übersicht über Begriffe im Familienrecht.</p>
                  </div> : <div className="glossar-cont">
                    <h3 className="argesto glossar-def">Definition</h3>
                    <p className="argesto glossar-desc">Wählen Sie einen Begriff aus, um die Definitionen einzusehen.</p>
                  </div>}
                <div className="divider-glossar"></div>
                <h3 className="argesto center-header">Haben Sie weitere Fragen?</h3>
                <p className="center-desc">Kontaktieren Sie uns.</p>
                <div className="contact-cont flex">
                  <div className="flex">
                    <div className="full">
                      <input
                        placeholder="Name"
                        className="i-half"
                        ref={this.form_name}
                      ></input>
                      <input
                        placeholder="E-Mail"
                        className="i-half"
                        ref={this.form_mail}
                      ></input>
                    </div>
                    <div className="full">
                      <input
                        type="number"
                        placeholder="Telefonnummer"
                        className="i-full"
                        ref={this.form_phone}
                      ></input>
                    </div>
                    {/* <div className="full">
                  <input
                    placeholder="Firma"
                    className="i-full"
                    ref={this.form_comp}
                  ></input>
                </div>
                <div className="full">
                  <input
                    placeholder="Position"
                    className="i-half"
                    ref={this.form_posi}
                  ></input>
                  <input
                    placeholder="Land"
                    className="i-half"
                    ref={this.form_country}
                  ></input>
                </div> */}
                    <div className="full">
                      <textarea
                        placeholder="Nachricht"
                        className="i-full"
                        ref={this.form_msg}
                      ></textarea>
                    </div>
                    <label className="w-checkbox checkbox-cont flex row">
                      <input
                        ref={this.form_gdpr}
                        className="checkbox"
                        type="checkbox"
                      ></input>
                      <p className="checkbox-label">
                        Ich stimme der Verarbeitung meiner Daten basierend auf
                        dieser&nbsp;
                        <a
                          href="https://www.kanzlei-e-lutz.de/de/datenschutzerklaerung"
                          target={"_blank"}
                          className="accented"
                        >
                          Datenschutzerklärung
                        </a>
                        &nbsp; zu.
                      </p>
                    </label>
                    {this.state.error ? (
                      <div className="full">
                        <p className="body error-msg">
                          Bitte füllen Sie zumindest die Felder E-Mail und Nachricht
                          aus und stimmen der Datenschutzerklärung zu.
                        </p>
                      </div>
                    ) : null}
                    {this.state.success ? (
                      <div className="full">
                        <p className="body success-msg">
                          Ihre Nachricht wurde erfolgreich versendet! Vielen Dank!
                        </p>
                      </div>
                    ) : null}
                    <div className="full contact">
                      <button onClick={this.sendMail} className="default">
                        Senden
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <FooterDE />
      </div >
    );
  }
}

export default Glossar;
