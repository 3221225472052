import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News9 from "../../assets/blog_9.jpg";
import { Link } from "react-router-dom";

class News_9 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Zwangsvollstreckung von Kindesunterhalt
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Zwangsvollstreckung von Kindesunterhalt nach deutschem Recht"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Zwangsvollstreckung von Kindesunterhalt</h1>
              <p className="body desc">01.12.24</p>
              <img src={News9}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Die Zwangsvollstreckung von Kindesunterhalt ist ein wichtiges Instrument, um sicherzustellen, dass Unterhaltsberechtigte – insbesondere Kinder – ihren rechtlich zugesicherten Unterhalt erhalten. Das deutsche Recht bietet hierzu verschiedene Mechanismen, die dem Unterhaltsberechtigten helfen, seine Ansprüche durchzusetzen, wenn der Unterhaltspflichtige seinen Verpflichtungen nicht freiwillig nachkommt.
                </p>
                <h3>Grundlagen des Kindesunterhalts</h3>
                <p className="body">
                  Der Kindesunterhalt ist ein gesetzlich festgelegter Anspruch, der sich aus den §§ 1601 ff. des Bürgerlichen Gesetzbuches (BGB) ergibt. Er dient dazu, den Lebensbedarf des Kindes zu decken. Die Höhe des zu zahlenden Unterhalts wird in der Regel anhand der sogenannten Düsseldorfer Tabelle berechnet. Unterhaltspflichtig sind in erster Linie die Eltern, wobei die Verpflichtung nach ihrer Leistungsfähigkeit beurteilt wird.</p>
                <h3>Wann kommt es zur Zwangsvollstreckung?</h3>
                <p className="body">
                  Die Zwangsvollstreckung wird notwendig, wenn der Unterhaltspflichtige trotz einer bestehenden Verpflichtung nicht zahlt. Voraussetzung ist in der Regel ein vollstreckbarer Titel, aus dem sich der Anspruch auf Unterhalt ergibt. Beispiele für solche Titel sind:</p>
                <ul className="body">
                  <li>Urteile und gerichtliche Beschlüsse, die den Unterhalt festlegen</li>
                  <li>Gerichtliche Vergleiche</li>
                  <li>Jugendamtsurkunden, die als vollstreckbare Unterhaltsanerkennung dienen</li>
                </ul>
                <p className="body">Ohne einen solchen Titel ist eine Zwangsvollstreckung nicht möglich.</p>
                <h3>Vorgehen bei der Zwangsvollstreckung</h3>
                <p className="body">
                  Die Zwangsvollstreckung erfolgt durch die Gerichtsvollzieher oder über gerichtliche Verfahren. Der Ablauf gestaltet sich in mehreren Schritten:</p>
                <ol className="body">
                  <li><strong>Einleitung der Zwangsvollstreckung: </strong>Der Unterhaltsberechtigte (bzw. dessen gesetzlicher Vertreter) beantragt die Zwangsvollstreckung beim zuständigen Gericht. Dies geschieht durch Vorlage des Titels und gegebenenfalls des Nachweises über den Zahlungsrückstand.</li>
                  <li><strong>Pfändung von Einkommen: </strong>Eine häufig genutzte Maßnahme ist die Lohn- oder Gehaltspfändung gemäß § 850c Zivilprozessordnung (ZPO). Dabei wird der Arbeitgeber verpflichtet, den pfändbaren Teil des Einkommens direkt an den Unterhaltsberechtigten zu überweisen.</li>
                  <li><strong>Pfändung von Konten: </strong>Auch die Kontopfändung kann beantragt werden. Hierbei wird ein Teil des Kontoguthabens des Unterhaltspflichtigen gepfändet.</li>
                  <li><strong>Eidesstattliche Versicherung und Vermögensauskunft: </strong>Wenn die Pfändung erfolglos bleibt, kann der Unterhaltsberechtigte den Unterhaltspflichtigen zur Abgabe einer Vermögensauskunft zwingen (§ 802c ZPO). Diese legt offen, welche Vermögenswerte vorhanden sind.</li>
                  <li><strong>Erzwingung durch Haft: </strong>In Extremfällen, wenn der Unterhaltspflichtige sich der Verpflichtung absichtlich entzieht, kann eine Erzwingungshaft gemäß § 802g ZPO beantragt werden. Dies ist jedoch ein letztes Mittel.</li>
                </ol>
                <h3>Besonderheiten beim Kindesunterhalt</h3>
                <ul className="body">
                  <li><strong>Vorrang des Kindesunterhalts: </strong>Kindesunterhalt hat in der Regel Vorrang vor anderen Schulden des Unterhaltspflichtigen. Das bedeutet, dass er bei der Berechnung des pfändbaren Einkommens zuerst berücksichtigt wird.</li>
                  <li><strong>Rückständiger Unterhalt: </strong>Neben laufendem Unterhalt kann auch rückständiger Unterhalt vollstreckt werden. Dieser muss allerdings konkret beziffert sein.</li>
                  <li><strong>Unterhaltsvorschuss: </strong>Wenn die Durchsetzung der Unterhaltszahlung lange dauert, kann der Unterhaltsberechtigte beim Jugendamt einen Unterhaltsvorschuss beantragen. Dieser wird später vom säumigen Unterhaltspflichtigen zurückgefordert.</li>
                </ul>
                <h3>Rechte und Pflichten der Beteiligten</h3>
                <p className="body">
                  Der Unterhaltspflichtige hat das Recht, sich gegen eine unrechtmäßige Zwangsvollstreckung zu wehren, beispielsweise durch Einwendungen gemäß § 767 ZPO, wenn die Unterhaltsforderung unberechtigt ist. Gleichzeitig ist der Unterhaltspflichtige verpflichtet, vollständig und wahrheitsgemäß über sein Einkommen und Vermögen Auskunft zu geben.</p>
                <h3>Praktische Tipps für Unterhaltsberechtigte</h3>
                <ul className="body">
                  <li><strong>Rechtzeitige Titulierung: </strong>Eltern sollten den Unterhaltsanspruch frühzeitig durch eine Jugendamtsurkunde oder ein Gerichtsurteil sichern.</li>
                  <li><strong>Juristische Beratung: </strong>Die Unterstützung durch einen Anwalt oder das Jugendamt kann bei der Durchsetzung der Ansprüche hilfreich sein.</li>
                  <li><strong>Langfristige Planung: </strong>Die Zwangsvollstreckung kann zeitintensiv sein. Es ist wichtig, rechtzeitig Maßnahmen zu ergreifen, um Unterhaltsansprüche abzusichern.</li>
                </ul>
                <h3>Fazit</h3>
                <p className="body">
                  Die Zwangsvollstreckung von Kindesunterhalt ist ein entscheidendes Instrument, um sicherzustellen, dass Kinder ihren gesetzlichen Anspruch auf Unterhalt erhalten. Das deutsche Recht bietet dazu umfangreiche Möglichkeiten, von der Gehaltspfändung bis hin zur Erzwingungshaft. Um erfolgreich zu sein, ist es jedoch wichtig, über einen vollstreckbaren Titel zu verfügen und gegebenenfalls professionelle Unterstützung in Anspruch zu nehmen.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_9;
