import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import Home1 from "../../assets/home_5.jpg";
import Home2 from "../../assets/anwalt_notdienst.jpg";
import PA from "../../assets/pflichtanwalt.jpg"
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //this.fetchNews();
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Homepage</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Homepage" />
          <meta
            property="og:description"
            content="Wir vertreten Sie im Familienrecht und im Migrationsrecht und entwickeln maßgeschneiderte
            rechtliche Strategien, um Ihre Ziele zu erreichen. In dringenden Notfällen steht Ihnen unser anwaltlicher
            Notdienst auch außerhalb unserer üblichen Geschäftszeiten zur Verfügung."
          />
        </MetaTags>
        <NavDE />
        <div className="hero home">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v normal">
              <span className="hero-span">Kanzlei für</span>
              <h2 className="hero-header argesto">Familienrecht und Migrationsrecht</h2>
              <Link reloadDocument className="default inverse" to="/de/kontakt">
                <span>Kontakt</span>
              </Link></div>
          </div>
        </div>
        <div className="section flex center-h combined">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Leistungen</h2>
            </div>
            <div className="half">
              <p className="body padding-3 justify-text">
                Willkommen bei unserer Anwaltskanzlei für Familienrecht und Migrationsrecht in Stuttgart: Wir bieten
                Ihnen klare Lösungen für komplexe rechtliche Angelegenheiten. Wir analysieren Ihre Situation gründlich
                und entwickeln rechtliche Strategien, die auf Ihre Bedürfnisse zugeschnitten sind. Kontaktieren Sie uns
                noch heute, um einen Beratungstermin zu vereinbaren. Wir beraten Sie in unserer Anwaltskanzlei in
                Stuttgart am Olgaeck, um Ihnen mit unserem Fachwissen und unserer Erfahrung im Familienrecht und
                Migrationsrecht zu helfen.
              </p>
              <div className="inline-block"><h3 className="sp-1 argesto">Familienrecht</h3>
                <Link reloadDocument className="default m-top-2" to="/de/familienrecht">
                  <span>Mehr erfahren</span>
                </Link>
              </div>
              <div className="inline-block less-bot"><h3 className="sp-1 argesto">Migrationsrecht</h3>
                <Link reloadDocument className="default m-top-2" to="/de/migrationsrecht">
                  <span>Mehr erfahren</span>
                </Link>
              </div>
            </div>
            <div className="w-image half">
              <img className="img-full" src={Home1}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h mob">
          <div className="medium flex">
            <div className="half">
              <img src={Home1}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h no-bot special">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Pflichtanwalt</h2>
            </div>
            <div className="w-image half">
              <img src={PA}></img>
            </div>
            <div className="half">
              <p className="body padding-4 justify-text marg-bot">
                Brauchen Sie schnellstmöglich Rechtsberatung bei Haftunterbringung zur Abschiebung, aber
                wissen nicht, wo Sie anfangen sollen? Unser Pflichtanwalt-Service bietet Ihnen die rechtliche
                Unterstützung, die Sie benötigen, ohne dass Sie lange nach einem Anwalt suchen müssen.
                Sichern Sie Ihre Rechte mit Rechtsanwältin Lutz als Ihre Pflichtanwältin.
              </p>
              <a className="default padding-4 no-left" href="tel:015142800153" >Jetzt anrufen</a>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Anwaltlicher Notdienst</h2>
            </div>
            <div className="half">
              <p className="body padding-3 but justify-text marg-bot">
                In dringenden Notfällen sind wir für Sie da. Unser anwaltlicher Notdienst steht Ihnen auch außerhalb
                unserer regulären Geschäftszeiten zur Verfügung. Wir verstehen, dass rechtliche Probleme keine
                Uhrzeiten kennen. Daher können Sie sich jederzeit auf unsere schnelle und kompetente Unterstützung
                verlassen. Kontaktieren Sie uns direkt über unsere Notfallnummer.
              </p>
              <a className="default no-left" href="tel:015142800153" >Jetzt anrufen</a>
            </div>
            <div className="w-image half">
              <img src={Home2}></img>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote justify-text">
            Unser Blog liefert fundierte Informationen, praktische Ratschläge und die aktuellen Entwicklungen in
            den Bereichen Familienrecht und Migrationsrecht. Hier finden Sie Artikel und Fachbeiträge über
            aktuelle Entwicklungen zu rechtlich relevanten Fragen im Familienrecht und im Migrationsrecht.
          </span>
          <Link reloadDocument className="default inverse" to="/de/blog">
            <span>Blog</span>
          </Link>
        </div>
        <div className="section flex center-h combined more-mrg">
          <div className="medium flex">
            <div className="heading1-cont flex center-v center-h col">
              <h2 className="argesto sub center">Bewertungen und Kundenstimmen</h2>
            </div>
            <div className="full a-widget">
              <div className="anwalt-de-home" data-anw-widget="121dfea57d25a3"></div>
            </div>
          </div>
        </div>
        <div className="section full home flex no-bg center-h center-v">
          <span className="bg-quote long justify-text">
            Ich übe meine Tätigkeit in Bürogemeinschaft mit <a className="quote-link" target="_blank" href="https://www.kanzlei-eggstein.de/">Rechtsanwältin Bettina Eggstein</a> und <a className="quote-link" target="_blank" href="https://www.ra-interaktiv.de/">Rechtsanwalt
              Marc Wennberg</a> aus. Rechtsanwältin Eggstein ergänzt das Tätigkeitsspektrum der Kanzlei als
            Fachanwältin für Familienrecht sowie Miet- und Wohnungseigentumsrecht und Rechtsanwalt
            Wennberg als Fachanwalt für Bau- und Architektenrecht sowie Fachanwalt für Verwaltungsrecht
            ideal. </span>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default no-margin" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div >
    );
  }
}

export default Home;
