import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import Home2 from "../../assets/familienrecht.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

class Familienrecht extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intro = React.createRef();
  }

  componentDidMount() {
    //this.fetchNews();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Familienrecht</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Familienrecht" />
          <meta
            property="og:description"
            content="Die Kanzlei ist darauf spezialisiert, Ihnen in allen Fragen des Familienrechts professionelle
            Unterstützung zu bieten. Von Scheidungen über Sorgerechtsstreitigkeiten bis hin zu
            Unterhaltsfragen – wir stehen Ihnen mit unserer Erfahrung und Kompetenz zur Seite, um
            Ihre Interessen zu vertreten. Wir bieten eine sachliche und pragmatische
            Herangehensweise, um Ihre familienrechtlichen Angelegenheiten rund um die Familie
            nachhaltig und effizient zu lösen."
          />
        </MetaTags>
        <NavDE />
        <div className="hero home familienrecht less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <span className="hero-span">Kanzlei für</span>
              <h2 className="hero-header argesto">Familienrecht</h2>
              <Link reloadDocument className="default inverse" to="/de/kontakt">
                <span>Kontakt</span>
              </Link>
            </div>
          </div>
        </div>
        <div ref={this.intro} className="section flex center-h first-less">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Familienrecht</h2>
            </div>
            <div className="w-image half">
              <img src={Home2}></img>
            </div>
            <div className="half">
              <p className="body padding-4 justify-text tzu">
                Recht und Familie – zwei Säulen des Lebens, die manchmal miteinander kollidieren. Doch in Zeiten der
                Uneinigkeit und rechtlichen Herausforderungen steht Ihnen unsere Anwaltskanzlei für Familienrecht in
                Stuttgart zur Seite. Wir bieten Ihnen umfassende rechtliche Unterstützung in allen Angelegenheiten
                rund um Familie und Partnerschaft. Egal, ob es um Scheidungen, Sorgerechtsstreitigkeiten,
                Unterhaltsfragen oder Erbschaftsangelegenheiten geht – wir setzen uns leidenschaftlich dafür ein, Ihre
                Interessen zu vertreten und eine für alle Seiten faire Lösung zu finden. Unser Ziel ist es, Ihnen in dieser
                emotional belastenden Zeit mit Rat und Tat zur Seite zu stehen.
              </p>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Wir vertreten Sie u. a. in folgenden Bereichen:</h2>
            </div>
            <div className="full">
              <ul className="body">
                <li className="first-li">Scheidung</li>
                <li>Eheverträge</li>
                <li>Kindesunterhalt</li>
                <li>Trennungsunterhalt</li>
                <li>Nachehelicher Unterhalt</li>
                <li>Sorgerechtsverfahren</li>
                <li>Umgangsverfahren</li></ul>
              <div className="medium flex">
                <a className="default" href="tel:015142800153" >Jetzt anrufen</a>
              </div>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote justify-text">
            Familienrechtliche Angelegenheiten können komplex, emotional und herausfordernd sein.
            Unser Blog soll Ihnen dabei helfen, sich in dieser rechtlichen Landschaft zurechtzufinden und
            Ihre familiären Angelegenheiten mit Vertrauen und Rechtssicherheit zu bewältigen. Bleiben
            Sie informiert - das ist unser Ziel.
          </span>
          <Link reloadDocument className="default inverse" to="/de/blog">
            <span>Zum Blog</span>
          </Link>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="full">
              <p className="body">Wir hören Ihnen aufmerksam zu, analysieren Ihre Situation gründlich und entwickeln
                maßgeschneiderte rechtliche Strategien, die auf Ihre individuellen Bedürfnisse zugeschnitten
                sind. Wir kämpfen für Ihr Recht und begleiten Sie auf dem Weg zu einer neuen Perspektive.
                Kontaktieren Sie uns noch heute, um einen Beratungstermin zu vereinbaren. Ihre Familie
                verdient die bestmögliche rechtliche Unterstützung – und wir sind hier, um Ihnen zu helfen.</p>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default no-margin" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div >
    );
  }
}

export default Familienrecht;
