import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import FR from "../../assets/familienrecht.jpg";
import MR from "../../assets/migrationsrecht.jpg";
import AN from "../../assets/widerruf.jpg";
import VMFR from "../../assets/Außergerichtliche Vollmacht in Familiensachen (1).pdf";
import VMFRG from "../../assets/Gerichtliche  Vollmacht in Familiensachen.pdf";
import WB from "../../assets/Widerrufsbelehrung (1).pdf";
import VMMR from "../../assets/Außergerichtliche Vollmacht in Verwaltungssachen (1).pdf";
import VMMRG from "../../assets/Gerichtliche Vollmacht in Verwaltungssachen (1).pdf";
import DH from "../../assets/Hinweise zum Datenschutz (1).pdf";
import PI from "../../assets/pdf_icon.png";
import { Link } from "react-router-dom";

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Formulare</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Formulare" />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="hero home formulare less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <h2 className="hero-header argesto">Formulare</h2>
            </div>
          </div>
        </div>
        <div
          ref={this.intro}
          className="section flex first intro center-h less-bot"
        >
          <div id="formular" className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Formulare</h2>
            </div>
            <div>
              <p className="body justify-text form-intro">
                Unsere Formulare sind der schnellste und einfachste Weg, um mit uns in Kontakt zu
                treten. Nach Eingang dieser Formulare können wir direkt mit der Bearbeitung Ihres
                Anliegens beginnen. So können wir Ihre Anfragen effizient bearbeiten und Ihre
                Bedürfnisse prompt erfüllen. Hier sind unsere wichtigsten Formulare:
              </p>
            </div>
            <div className="all-thirds-container flex">
              <div className="third-container third form">
                <img className="img-full" src={FR}></img>
                <h4 className="argesto lh">Familienrecht</h4>

                <a
                  target="_blank"
                  href={VMFR}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Außergerichtliche Vollmacht</p>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={VMFRG}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Gerichtliche Vollmacht</p>
                  </div>
                </a>
              </div>
              <div className="third-container third form">
                <img className="img-full" src={MR}></img>
                <h4 className="argesto lh">Verwaltungsrecht</h4>
                <a
                  target="_blank"
                  href={VMMR}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Außergerichtliche Vollmacht</p>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={VMMRG}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Gerichtliche Vollmacht</p>
                  </div>
                </a>
              </div>
              <div className="third-container third form">
                <img className="img-full" src={AN}></img>
                <h4 className="argesto lh">Sonstiges</h4>
                <a
                  target="_blank"
                  href={WB}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Widerrufsbelehrung</p>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={DH}
                >
                  <div className="flex center-v marg-top">
                    <img height="32px" className="form" src={PI}></img>
                    <p className="default">Datenschutzhinweise</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default no-margin" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Kontakt;
