import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
    this.form_name = React.createRef();
    this.form_mail = React.createRef();
    this.form_msg = React.createRef();
    this.form_phone = React.createRef();
    this.form_gdpr = React.createRef();
    this.state = {
      error: false,
      success: false,
    };
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  sendMail = (e) => {
    if (
      !this.form_gdpr.current.checked ||
      !this.form_mail.current.value ||
      !this.form_msg.current.value
    ) {
      this.setState({
        error: true,
      });
    } else {
      this.form_gdpr.current.checked = false;
      this.setState({
        error: false,
        success: true,
      });
      let html =
        "<h1>" +
        "Contact Form Submission Values<br><br>" +
        "</h1><p>Name: " +
        this.form_name.current.value +
        "</p>" +
        "<p>E-Mail: " +
        this.form_mail.current.value +
        "</p>" +
        this.form_msg.current.value +
        "</p>" +
        this.form_phone.current.value +
        "</p>";
      fetch("https://hooks.zapier.com/hooks/catch/7306825/2yi9ti5/", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ html }),
      });
      this.form_name.current.value = "";
      this.form_mail.current.value = "";
      this.form_msg.current.value = "";
      this.form_phone.current.value = "";
    }
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Kontakt</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Kontakt" />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="hero home less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <h2 className="hero-header argesto">Kontakt</h2>
              <button onClick={this.introScroll} className="default inverted">
                Zum Formular
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.intro}
          className="section flex first intro center-h less-bot"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Kontakt</h2>
            </div>
            <div>
              <p className="body">
                Senden Sie uns Ihre Anfrage. Wir werden uns zeitnah für einen Terminvorschlag mit Ihnen in Verbindung setzen.<br></br>
                Schildern Sie uns kurz, in welcher Angelegenheit wir Sie beraten können.
              </p>
            </div>
            <div className="contact-cont flex">
              <div className="two-thirds flex">
                <div className="full">
                  <input
                    placeholder="Name"
                    className="i-half"
                    ref={this.form_name}
                  ></input>
                  <input
                    placeholder="E-Mail"
                    className="i-half"
                    ref={this.form_mail}
                  ></input>
                </div>
                <div className="full">
                  <input
                    type="number"
                    placeholder="Telefonnummer"
                    className="i-full"
                    ref={this.form_phone}
                  ></input>
                </div>
                {/* <div className="full">
                  <input
                    placeholder="Firma"
                    className="i-full"
                    ref={this.form_comp}
                  ></input>
                </div>
                <div className="full">
                  <input
                    placeholder="Position"
                    className="i-half"
                    ref={this.form_posi}
                  ></input>
                  <input
                    placeholder="Land"
                    className="i-half"
                    ref={this.form_country}
                  ></input>
                </div> */}
                <div className="full">
                  <textarea
                    placeholder="Nachricht"
                    className="i-full"
                    ref={this.form_msg}
                  ></textarea>
                </div>
                <label className="w-checkbox checkbox-cont flex row">
                  <input
                    ref={this.form_gdpr}
                    className="checkbox"
                    type="checkbox"
                  ></input>
                  <p className="checkbox-label">
                    Ich stimme der Verarbeitung meiner Daten basierend auf
                    dieser&nbsp;
                    <a
                      href="https://www.kanzlei-e-lutz.de/de/datenschutzerklaerung"
                      target={"_blank"}
                      className="accented"
                    >
                      Datenschutzerklärung
                    </a>
                    &nbsp; zu.
                  </p>
                </label>
                {this.state.error ? (
                  <div className="full">
                    <p className="body error-msg">
                      Bitte füllen Sie zumindest die Felder E-Mail und Nachricht
                      aus und stimmen der Datenschutzerklärung zu.
                    </p>
                  </div>
                ) : null}
                {this.state.success ? (
                  <div className="full">
                    <p className="body success-msg">
                      Ihre Nachricht wurde erfolgreich versendet! Vielen Dank!
                    </p>
                  </div>
                ) : null}
                <div className="full contact">
                  <button onClick={this.sendMail} className="default">
                    Senden
                  </button>
                </div>
              </div>
              <div className="third">
                <p className="body contact">
                  Kanzlei Elisabeth Lutz
                  <br></br>Olgastraße 57a<br></br>70182 Stuttgart
                  <br></br>Deutschland<br></br>
                  <br></br>0711 - 2364402<br></br>0151 - 42800153<br></br>info@kanzlei-e-lutz.de
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h no-bot">
          <div className="medium">
            <div className="bottom-divider footer"></div>
          </div>
        </div>
        <div className="section flex center-h contact-mrg">
          <div className="medium flex">
            <div className="full a-widget">
              <div className="anwalt-de-home" data-anw-widget="1602136cfdf123"></div>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Kontakt;
