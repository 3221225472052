import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News5 from "../../assets/blog_5.jpg";
import { Link } from "react-router-dom";

class News_5 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Erwerbsobliegenheiten bei der Scheidung: Ab wann muss ich arbeiten?
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Haftbeschwerde"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Haftbeschwerde</h1>
              <p className="body desc">01.06.24</p>
              <img src={News5}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Die Haftbeschwerde im deutschen Migrationsrecht ist ein Rechtsmittel, das gegen die Anordnung oder Fortsetzung der Abschiebungshaft erhoben werden kann. Abschiebungshaft dient dazu, die Abschiebung eines ausreisepflichtigen Ausländers sicherzustellen, wenn Fluchtgefahr besteht oder andere Gründe eine Sicherung der Abschiebung erfordern.
                </p>
                <h3>Zweck und Rechtsgrundlage</h3>
                <p className="body">
                  Die Haftbeschwerde bietet den Betroffenen die Möglichkeit, die Rechtmäßigkeit ihrer Inhaftierung durch ein Gericht überprüfen zu lassen. Die rechtlichen Grundlagen für die Abschiebungshaft finden sich in den §§ 62 bis 62b des Aufenthaltsgesetzes (AufenthG). Die Haftbeschwerde ist in der Regel beim zuständigen Amtsgericht einzureichen, das die Haft angeordnet hat.
                </p>
                <h3>Voraussetzungen und Verfahren</h3>
                <p className="body">
                  Die Haftbeschwerde kann sowohl gegen die ursprüngliche Anordnung der Haft als auch gegen deren Verlängerung eingelegt werden. Folgende Aspekte sind dabei zu beachten:</p>
                <br></br>
                <ul className="body"><li><strong>Beschwerdefrist:</strong> Die Haftbeschwerde muss innerhalb einer bestimmten Frist nach der Bekanntgabe der Haftanordnung eingelegt werden. Diese Frist beträgt in der Regel zwei Wochen.</li>
                  <li><strong>Begründung:</strong> Die Beschwerde muss schriftlich erfolgen und begründet werden. Der Beschwerdeführer sollte darlegen, warum er die Haft für rechtswidrig hält. Typische Gründe können sein:</li>
                  <ul className="body"><li>Mangelnde Fluchtgefahr</li>
                    <li>Fehler in der Haftanordnung</li>
                    <li>Anträge werden zu spät oder unvollständig gestellt und das Verfahren wird somit nicht
                      weiter betrieben</li></ul>
                  <li><strong>Gerichtliche Prüfung:</strong> Das Gericht prüft die Haftbeschwerde und entscheidet,
                    ob die Haftanordnung rechtmäßig ist. Dabei werden sowohl die formellen als
                    auch die materiellen Voraussetzungen der Haft überprüft.</li>
                  <li><strong>Entscheidung:</strong> Das Gericht kann die Haft entweder bestätigen, abändern oder
                    aufheben. Wird die Haft aufgehoben, ist der Betroffene unverzüglich
                    freizulassen.</li>
                </ul><br></br>
                <h3>Rechtsschutz und weitere Rechtsmittel</h3>
                <p className="body">
                  Neben der Haftbeschwerde gibt es auch die Möglichkeit, eine sogenannte Haftprüfung zu beantragen. Diese kann jederzeit während der Haftdauer erfolgen und führt zu einer erneuten Überprüfung der Haftvoraussetzungen durch das Gericht.
                  Darüber hinaus können Betroffene auch den Eilrechtsschutz nach § 80 Abs. 5 der Verwaltungsgerichtsordnung (VwGO) in Anspruch nehmen, um gegen die Anordnung der Abschiebung oder die damit verbundene Haft vorzugehen.
                </p>
                <h3>Besondere Schutzvorschriften</h3>
                <p className="body">
                  Im Rahmen der Abschiebungshaft gelten besondere Schutzvorschriften, um die Rechte der Betroffenen zu wahren:</p>
                <br></br>
                <ul className="body"><li><strong>Unterbringung: </strong>Abschiebungshäftlinge sind grundsätzlich getrennt von
                  Strafgefangenen unterzubringen.</li>
                  <li><strong>Information und Anhörung: </strong>Die Betroffenen müssen umfassend über ihre
                    Rechte informiert und angehört werden.</li>
                  <li><strong>Rechtsbeistand: </strong>Den Betroffenen steht das Recht zu, sich eines
                    Rechtsbeistandes zu bedienen.</li></ul><br></br>
                <h3>Bedeutung der Haftbeschwerde</h3>
                <p className="body">
                  Die Haftbeschwerde ist ein zentrales Instrument des Rechtsschutzes im Migrationsrecht. Sie stellt sicher, dass die Freiheitsentziehung, die mit der Abschiebungshaft verbunden ist, nur unter strikter Beachtung der gesetzlichen Vorschriften erfolgt. Durch die gerichtliche Überprüfung wird der Schutz der persönlichen Freiheit der Betroffenen gewahrt und Missbrauch verhindert.
                  Zusammengefasst ist die Haftbeschwerde ein wesentlicher Bestandteil des Rechtsstaatsprinzips im deutschen Migrationsrecht, das sicherstellt, dass staatliche Maßnahmen im Bereich der Abschiebungshaft einer gerichtlichen Kontrolle unterliegen und die Rechte der Betroffenen geschützt werden.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_5;
