import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import Home2 from "../../assets/migrationsrecht.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

class Migrationsrecht extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intro = React.createRef();
  }

  componentDidMount() {
    //this.fetchNews();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Migrationsrecht</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Migrationsrecht" />
          <meta
            property="og:description"
            content="Wir unterstützen Sie bei allen rechtlichen Aspekten des Einwanderungsprozesses. Von
            Visaanträgen über Aufenthalts- und Arbeitserlaubnisse bis hin zu
            Familienzusammenführungen, Staatsbürgerschaftsanträgen und in Abschiebungsverfahren
            stehen wir Ihnen zur Seite, um Ihre Rechte zu schützen und ein reibungsloses Verfahren zu
            gewährleisten."
          />
        </MetaTags>
        <NavDE />
        <div className="hero home migrationsrecht less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <span className="hero-span">Kanzlei für</span>
              <h2 className="hero-header argesto">Migrationsrecht</h2>
              <Link reloadDocument className="default inverse" to="/de/kontakt">
                <span>Kontakt</span>
              </Link>
            </div>
          </div>
        </div>
        <div ref={this.intro} className="section flex center-h first-less">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Migrationsrecht</h2>
            </div>
            <div className="w-image half">
              <img src={Home2}></img>
            </div>
            <div className="half">
              <p className="body padding-4 justify-text tzu">
                Unsere Kanzlei unterstützt Sie bei allen rechtlichen Aspekten des Einwanderungsprozesses.
                Wir verstehen die Bedeutung jeder Entscheidung im Migrationsprozess und setzen uns
                leidenschaftlich dafür ein, die bestmöglichen Ergebnisse für Sie zu erzielen.
                Vertrauen Sie auf unsere Erfahrung und unser Engagement im Migrationsrecht. Kontaktieren
                Sie uns noch heute, um einen Beratungstermin zu vereinbaren. Wir stehen Ihnen mit Rat
                und Tat zur Seite, um Ihre Einwanderungsziele zu verwirklichen und Ihnen eine erfolgreiche
                Zukunft zu ermöglichen.
                Kontaktieren Sie uns für eine Beratung und lassen Sie uns Ihnen helfen, Ihre
                Einwanderungsziele zu erreichen.
              </p>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Wir vertreten Sie u. a. in folgenden Bereichen:</h2>
            </div>
            <div className="full">
              <ul className="body">
                <li className="first-li">Niederlassungserlaubnis</li>
                <li>Aufenthaltstitel zu Erwerbszwecken</li>
                <li>Fachkräftezuwanderung</li>
                <li>Einbürgerung</li>
                <li>Familiennachzug</li>
                <li>Asylverfahren</li>
                <li>Abschiebeverbote</li>
                <li>Beschäftigungsduldung</li>
                <li>Chancenaufenthaltsrecht</li>
                <li>Änderung von Nebenbestimmungen von Aufenthaltstiteln:</li>
                <ul className="body">
                  <li>Wohnsitzauflage</li>
                  <li>Gestattung der Erwerbstätigkeit</li></ul>
              </ul>
              <div className="medium flex">
                <a className="default" href="tel:015142800153" >Jetzt anrufen</a>
              </div>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote justify-text">
            Unser Blog bietet Ihnen einen umfassenden Leitfaden durch die rechtlichen Aspekte der
            Einwanderung. In einer zunehmend globalisierten Welt ist das Verständnis der
            Einwanderungsgesetze und Einwanderungsverfahren von entscheidender Bedeutung, sei es
            für Einzelpersonen, Unternehmen oder Regierungen. Bleiben Sie informiert - das ist unser
            Ziel.
          </span>
          <Link reloadDocument className="default inverse" to="/de/blog">
            <span>Zum Blog</span>
          </Link>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div >
    );
  }
}

export default Migrationsrecht;
