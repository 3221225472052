import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import UeberUns1 from "../../assets/ueber_uns_1.jpg";
import BS from "../../assets/book_shelve_2.jpeg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsCounter: 0,
      bgImg: "half about-img-2 kanzlei one"
    };
    this.intro = React.createRef();
    this.newsSlider = this.newsSlider.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(
      () => this.newsSlider(),
      4000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  newsSlider() {
    let nC = this.state.newsCounter + 1;
    if (nC > 7) {
      nC = 0;
    }
    if (nC === 0) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei one",
      });
    } else if (nC === 1) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei two",
      });
    } else if (nC === 2) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei three",
      });
    } else if (nC === 3) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei five",
      });
    } else if (nC === 4) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei seven",
      });
    } else if (nC === 5) {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei nine",
      });
    } else {
      this.setState({
        newsCounter: nC,
        bgImg: "half about-img-2 kanzlei eleven",
      });
    }
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Kanzlei</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Kanzlei" />
          <meta
            property="og:description"
            content="Wir empfangen Sie zeitnah zu einem Erstberatungsgespräch unserer Kanzlei am Olgaeck -
            im Herzen Stuttgarts. Suchen Sie einen Anwalt für Familienrecht in Stuttgart oder einen
            Anwalt für Migrationsrecht, sind Sie bei uns genau richtig."
          />
        </MetaTags>
        <NavDE />
        <div className="hero home less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <h2 className="hero-header argesto">Kanzlei</h2>
              <button onClick={this.introScroll} className="default inverted">
                Mehr erfahren
              </button></div>
          </div>
        </div>
        <div ref={this.intro} className="section flex center-h combined">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Rechtsanwältin Elisabeth Lutz</h2>
            </div>
            <div className="half">
              <p className="body padding-3 justify-text quote">
                "Seit einigen Jahren setzte ich mich als Juristin für Menschenrechte und faire Verfahren ein. Durch
                meine Erfahrungen wurde mir klar, wie groß der Bedarf an Rechtsberatung in den Bereichen des
                Familienrechts und des Migrationsrechts ist. Mein Ziel ist es, Menschen in Not über Grenzen hinweg
                zu helfen. Hierfür stehe ich Ihnen mit meiner Arbeit als Rechtsanwältin zur Verfügung."
              </p>
            </div>
            <div className="half">
              <img className="img-full" src={UeberUns1}></img>
            </div>
          </div>
        </div>
        <div className="section kanzlei full about-1 flex center-h center-v extra-margin">
          <div className="half red-bg kanzlei">
            <div className="flex row kanzlei-team">
              <div className="inline-block full">
                <p className="break full pod justify-text">Wir empfangen Sie zeitnah zu einem Erstberatungsgespräch unserer Kanzlei am Olgaeck - im Herzen Stuttgarts. Suchen Sie einen Anwalt für Familienrecht in Stuttgart oder einen Anwalt für Migrationsrecht, sind Sie bei uns genau richtig.</p>
              </div>
            </div>
          </div>
          <div className={this.state.bgImg}>
            <div onClick={this.newsSlider} className="slider-nav-backdrop">
              <span className="news-nav-next">{">"}</span>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Berufliche Erfahrungen</h2>
            </div>
            <div className="full">
              <ul className="body"><li>Studium der Rechtswissenschaften an der Eberhard Karls Universität Tübingen, 2015 - 2021</li>
                <li>Referendariat am Landgericht Ravensburg, 2021- 2023</li>
                <li>Beraterin bei Amnesty International, Arbeitskreis Asyl Ravensburg – Weingarten, 2021 - 2024</li>
                <li>Volontariat bei der deutschen Außenhandelskammer Malaysia in Kuala Lumpur, 2022 -2023</li>
                <li>Volontariat bei der Nichtregierungsorganisation Equal Legal Aid in Griechenland, Thessaloniki, 2023</li>
                <li>Zulassung als Rechtsanwältin, 2023</li>
                <li>Lehrgang zur Fachanwältin für Familienrecht, 2024 absolviert</li></ul>
            </div>
          </div>
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Mitgliedschaften</h2>
            </div>
            <div className="full">
              <ul className="body"><li>Deutscher Anwaltverein</li>
                <li>Arbeitsgemeinschaft Familienrecht</li>
                <li>Arbeitsgemeinschaft Migrationsrecht</li>
                <li>Deutscher Juristinnenbund</li></ul>
            </div>
          </div>
        </div>
        <div className="section kanzlei full about-1 flex center-h center-v">
          <div className="half about-img-2 kanzlei twelve"></div>
          <div className="half red-bg">
            <h4 className="argesto">Team</h4>
            <div className="flex row kanzlei-team">
              <div className="inline-block">
                <h3 className="argesto">Frau Giulia Sinko</h3>
                <p className="break mob">Auszubildende zur<br></br>Rechtsanwalts-<br></br>fachangestellten</p>
                <p className="break desk">Auszubildende zur<br></br>Rechtsanwaltsfachangestellten</p>
                <p className="break">Fremdsprachen: Englisch, Serbisch, Bosnisch</p>
              </div>
              <div className="inline-block">
                <h3 className="argesto">Frau Fercan Ferli</h3>
                <p className="break mob">Auszubildende zur<br></br>Rechtsanwalts-<br></br>fachangestellten</p>
                <p className="break desk">Auszubildende zur<br></br>Rechtsanwaltsfachangestellten</p>
                <p className="break">Fremdsprachen: Englisch, Türkisch</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div >
    );
  }
}

export default AboutUs;
