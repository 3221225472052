import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News1 from "../../assets/blog_1.jpg";
import { Link } from "react-router-dom";

class News_1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content blog">
        <MetaTags>
          <title>
            Kanzlei Elisabeth Lutz - Blog - Abschiebungen in Deutschland 2024: Eine kritische Betrachtung
          </title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta
            property="og:title"
            content="Kanzlei Elisabeth Lutz - Blog - Abschiebungen in Deutschland 2024: Eine kritische Betrachtung"
          />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first blog intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1 className="argesto">Abschiebungen in Deutschland 2024: Eine kritische Betrachtung</h1>
              <p className="body desc">10. März 2024</p>
              <img src={News1}></img>
              <div className="bottom-divider"></div>
              <div>
                <p className="body">
                  Im Jahr 2024 bleibt das Thema Abschiebung in Deutschland nach wie vor ein kontrovers diskutiertes
                  und emotionsgeladenes Thema. Die deutsche Migrationspolitik hat in den letzten Monaten
                  zahlreiche Veränderungen durchlaufen und Abschiebungen spielen eine zentrale Rolle in dieser
                  Debatte. In diesem Artikel werden wir einen genaueren Blick auf die Situation werfen und die
                  verschiedenen Aspekte dieses umstrittenen Themas beleuchten.
                </p>
                <h3>Die aktuelle Lage</h3>
                <p className="body">
                  Die Zahl der Abschiebungen in Deutschland hat sich in den letzten Jahren verändert, und es wird
                  deutlich, dass die Regierung bestrebt ist, konsequenter in der Durchsetzung von Abschiebungen
                  vorzugehen. Dabei stehen vor allem abgelehnte Asylbewerber im Fokus, die nicht mehr die
                  notwendigen Voraussetzungen für einen legalen Aufenthalt erfüllen. Die Zahlen der Betroffenen, die
                  einen Anwalt suchen, steigt stetig an. Wöchentlich gehen in unserer Kanzlei etwa fünf neue Fälle aus
                  der nahegelegenen Abschiebehafteinrichtung in Pforzheim von Personen ein, die kurz vor der
                  Abschiebung stehen und Rechtsbeistand brauchen.
                </p>
                <h3>Gründe für Abschiebungen</h3>
                <p className="body">
                  Es gibt verschiedene Gründe, warum Menschen in Deutschland abgeschoben werden. Dazu gehören
                  abgelehnte Asylanträge, Straftaten, die zu einer Ausweisung führen, oder das Erlöschen des
                  Aufenthaltsrechts aus anderen Gründen. Die Befürworter argumentieren, dass eine konsequente
                  Abschiebepolitik notwendig ist, um die Integrität des Asylsystems zu wahren und die öffentliche
                  Sicherheit zu gewährleisten.</p>
                <h3>Oft trifft es die Falschen</h3>
                <p className="body">
                  Richtig ist, dass nur Personen abgeschoben werden, die aktuell kein dauerhaftes Bleiberecht in
                  Deutschland innehaben. Das kann jedoch sehr unterschiedliche Gründe haben. In aller Regel werden
                  Personen abgeschoben, die sich verwaltungsrechtliche Fehler geleistet haben. Diese sind zum
                  Beispiel:</p><br></br>
                <ul className="body"><li>Auszug aus der Unterkunft für Geflüchtete ohne Erlaubnis von der Ausländerbehörde</li>
                  <li>Ausweispapiere werden nach deren Ablauf nicht neu bei der Ausländerbehörde beantragt
                    und abgeholt</li>
                  <li>Anträge werden zu spät oder unvollständig gestellt und das Verfahren wird somit nicht
                    weiter betrieben</li></ul><br></br>
                <p className="body">
                  In aller Regel sind diese Fehler und damit auch die die drohende Abschiebung vermeidbar. Die größte
                  Hürde ist hierbei zu verstehen, welche Pflichte die Betroffenen in den komplizierten
                  Verwaltungsverfahren haben. Kennen Sie all Ihre Pflichten im Verwaltungsverfahren? Haben Sie all
                  Ihre Pflichten erfüllt und stehen mit der zuständigen Ausländerbehörde regelmäßig im Kontakt? Nur
                  so können Sie sich sicher sein, dass Ihnen keine Abschiebung droht.</p><br></br>
                <p className="body">Wenden Sie sich gerne an uns, wenn Sie die rechtliche Unterstützung eines Rechtsanwalts brauchen.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_1;
