import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import Home2 from "../../assets/anwalt_notdienst.jpg";
import PA from "../../assets/pflichtanwalt.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

class Anwaltlicher_Notdienst extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intro = React.createRef();
  }

  componentDidMount() {
    //this.fetchNews();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 80;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Plichtanwalt</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Plichtanwalt" />
          <meta
            property="og:description"
            content="In dringenden Notfällen sind wir für Sie da. Unser anwaltlicher Notdienst steht Ihnen auch
            außerhalb unserer regulären Geschäftszeiten zur Verfügung. Wir verstehen, dass rechtliche
            Probleme keine Uhrzeiten kennen. Daher können Sie sich jederzeit auf unsere schnelle und
            kompetente Unterstützung verlassen."
          />
        </MetaTags>
        <NavDE />
        <div className="hero home anwalt-notdienst less">
          <div className="red-bg-hero"></div>
          <div className="hero-content flex center-h">
            <div className="hero-content-r flex center-v less">
              <h2 className="hero-header argesto">Pflichtanwalt & Anwaltlicher Notdienst</h2>
              <Link reloadDocument className="default inverse" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <div ref={this.intro} id="anwalt-notdienst-intro" className="section flex center-h first-less">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Unser anwaltlicher Notdienst</h2>
            </div>
            <div className="w-image half">
              <img src={Home2}></img>
            </div>
            <div className="half">
              <p className="body padding-4 marg-b-2 justify-text tzu">
                Unser Notfall-Service stellt Ihnen in Notfällen einen Rechtsanwalt aus Stuttgart zur Verfügung. Die
                Hotline agiert als Bindeglied zwischen den Betroffenen und den staatlichen Stellen, um schnell mit
                anwaltlicher Hilfe auf die Situationen reagieren zu können.<br></br><br></br>In diesen Notfällen sind wir für Sie da:
                <ul className="body">
                  <li className="first-li">Abschiebungen in das Herkunftsland: Wenn Sie bereits auf dem Weg zum Flughafen sind</li>
                  <li>Anordnung von Abschiebehaft: Wenn Sie dem Richter vorgeführt werden, bevor
                    freiheitsentziehende Maßnahmen angeordnet werden</li>
                  <li>Anordnung von Sicherungshaft: Wenn Sie dem Richter vorgeführt werden, bevor
                    freiheitsentziehende Maßnahmen angeordnet werden</li></ul>
              </p>
            </div>
          </div>
        </div>
        <div className="section flex center-h first-less">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Andere Notfälle</h2>
            </div>
            <div className="full">
              <p className="body">Auch im Familienrecht kann schneller anwaltlicher Beistand gefragt sein. Dies ist etwa in folgenden
                Fällen der Fall:</p>
              <ul className="body">
                <li className="first-li">Gewaltschutzverfahren</li>
                <li>Verfahren zum Schutze von Kindern</li>
                <li>Andere familiäre Notlagen</li></ul>
              <div className="medium flex">
                <a className="default" href="tel:015142800153" >Jetzt anrufen</a>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h first-less">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Einen Pflichtanwalt finden</h2>
            </div>
            <div className="half">
              <p className="body padding-3 justify-text">
                Das Rückführungsverbesserungsgesetz brachte Anfang 2024 bedeutende Änderungen mit sich,
                insbesondere in Bezug auf die ausreisepflichtigen Betroffenen. Eine wichtige Neuerung betrifft §
                62d des Aufenthaltsgesetzes, der die Durchsetzung fairer Verfahren sicherstellen soll. Gemäß
                dieser Regelung wird nun allen ausreisepflichtigen Personen, die noch keine anwaltliche Vertretung
                haben, ein Pflichtanwalt beigeordnet.
              </p>
            </div>
            <div className="w-image half">
              <img className="img-full" src={PA}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Wahl des Pflichtanwalts</h2>
            </div>
            <div className="full">
              <p className="body">
                Sie können sich einen Anwalt Ihrer Wahl als Pflichtanwalt beiordnen lassen. Sind Sie bereits
                Mandant unserer Kanzlei oder wollen es werden, können Sie sich einen Pflichtanwalt aus Stuttgart
                beiordnen lassen. Hierzu sollten Sie bei der Polizei und bei dem zuständigen Richter angeben, wen
                Sie sich beiordnen lassen wollen.<br></br>
                Unsere Kanzlei stellt Ihnen eine qualifizierte Pflichtanwältin zur Verfügung.</p>
              <div className="medium flex">
                <a className="default" href="tel:015142800153" >Jetzt anrufen</a>
              </div>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote justify-text">
            In dringenden rechtlichen Angelegenheiten ist es entscheidend, professionelle Beratung und Vertretung zu erhalten. Als Pflichtanwältin steht Ihnen Rechtsanwältin Lutz in jeder Phase des Prozesses zur Seite, um sicherzustellen, dass Ihre Interessen angemessen vertreten werden.
          </span>
          <a className="default inverse padding-1 no-left" href="tel:015142800153" >Rufen Sie uns an</a>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h quest">
              <p className="eye-catcher">Haben Sie noch Fragen?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link reloadDocument className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div >
    );
  }
}

export default Anwaltlicher_Notdienst;
